import React from 'react';
import PropTypes from 'prop-types';

import NodeBox from './NodeBox';
import './Timeline.css';

const Timeline = ({ nodeBoxes }) => (
  <div className="timeline">
    {nodeBoxes.map(({ label, nodes }) => (
      <NodeBox key={nodes && nodes[0] && nodes[0].id} label={label} nodes={nodes} />
    ))}
  </div>
);

Timeline.defaultProps = {
  nodeBoxes: [],
};

Timeline.propTypes = {
  nodeBoxes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      message: PropTypes.string,
      order: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      date: PropTypes.string,
      group: PropTypes.string,
    }),
  ),
};

export default Timeline;
