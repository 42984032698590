import React from 'react';

interface ErrorObject {
  type: string;
  message: string;
}

type SetErrorFunction = (name: string, error: ErrorObject) => void;
type ClearErrorsFunction = (name: string) => void;
type ValidationFunctionB = (value: string, setError: SetErrorFunction) => void;

interface Country {
  value: string;
  name: string;
  id: string;
  code: string;
}

export const formatPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return phoneNumber;
  const cleaned = phoneNumber.replace(/\D/g, '');

  // Check if the phone is USA
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    // Remove the matched extension code
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  // return unfomatted phone number
  return phoneNumber;
};

export const validateCharacterLimit = (
  nameOrEvent: string | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  value: string | undefined,
  maxLength: number,
  setError: SetErrorFunction,
) => {
  let name: string;

  // Check if we are dealing with an event or a string
  if (typeof nameOrEvent === 'string') {
    name = nameOrEvent; // We assume this is the field name
  } else {
    name = nameOrEvent.target.name;
    value = nameOrEvent.target.value;
    maxLength = nameOrEvent.target.maxLength;
  }

  if (value && value.length > maxLength - 1) {
    setError(name, {
      type: 'character limit',
      message: `You have reached the ${(maxLength - 1)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} character limit.`,
    });
  }
};

export const COUNTRY_SELECTION: { [key: string]: Country } = {
  '38': { value: 'Canada', name: 'Canada', id: '38', code: 'CA' },
  '230': {
    value: 'United States',
    name: 'United States',
    id: '230',
    code: 'US',
  },
  '76': {
    value: 'Great Britain',
    name: 'United Kingdom',
    id: '76',
    code: 'GB',
  },
};

export const getCountryId = (country: string) => {
  for (let key in COUNTRY_SELECTION) {
    const currentCountry = COUNTRY_SELECTION[key];
    if (currentCountry.name === country || currentCountry.code === country) {
      return currentCountry.id;
    }
  }
  return null;
};

export const handleValidation = (
  name: string,
  value: string,
  setError: SetErrorFunction,
  clearErrors: ClearErrorsFunction,
  ...validations: ValidationFunctionB[]
) => {
  clearErrors(name);
  if (value) {
    validations.forEach((validate) => {
      validate(value, setError); // Validate based on the value, not the event
    });
  }
};

/**
 * Converts a string to start case but, as opposed to lodash, keeps the special characters
 */
export const startCase = (s: string) =>
  s.replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
