import React from 'react';
import { getEnv } from '../../environment';
import { universalNavigationContextDefaultProps } from './UniversalNavigationContext';

const GlobalNavInternalContext = React.createContext({
  env: getEnv(),
  mainProps: {},
  ...universalNavigationContextDefaultProps,
  flags: {},
});

export default GlobalNavInternalContext;
