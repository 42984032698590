import queryString from 'query-string';
import request from './request';
import { getCampaignIdQueryParam, getCampaignStatusQueryParam } from '../utils/page';

const campaignIdQueryParam = getCampaignIdQueryParam();

const campaignStatusQueryParam = getCampaignStatusQueryParam();
// eslint-disable-next-line import/prefer-default-export
export async function fetchCampaignsData(campaignType, env, isPublicShopping) {
  const queryParams = {
    ...(campaignIdQueryParam && { campaignType }),
    ...(campaignIdQueryParam && { campaignId: campaignIdQueryParam }),
    ...(campaignStatusQueryParam && {
      campaignStatus: campaignStatusQueryParam,
    }),
  };
  const isCampaignWithQueryParam = campaignIdQueryParam
    ? `api/1/campaigns/default?${queryString.stringify(queryParams)}`
    : 'api/1/campaigns/default';
  const endpoint = isPublicShopping ? `public/api/1/campaigns?type=SHOP` : isCampaignWithQueryParam;
  // TODO, delete try useless on this file
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await request(endpoint, 'GET', env);
    return response;
  } catch (err) {
    throw err;
  }
}
