import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import GlobalNavInternalContext from '../../context/GlobalNavInternalContext';
import { formatRoutes } from '../../helpers/userProfile';
import SubMenu from '../NavigationMenu/SubMenu';
import MenuItem from '../NavigationMenu/MenuItem';
import Avatar from '../Avatar';
import UserProfileItem from './UserProfileItem';
import './UserProfile.css';

// TODO: the /menu endpoint should also return the orders item and we should filter out
// "Invoice History" or "Orders" in the front-end based on the FF value
const ORDERS_ITEM = {
  id: 'orders',
  label: 'Orders',
  route: '/my-account/orders',
};
const INVOICE_HISTORY_ID = 'invoice_history';
const replaceInvoiceHistory = (items) =>
  items.map((item) => {
    if (item.id === INVOICE_HISTORY_ID) return ORDERS_ITEM;
    return item;
  });

const UserProfile = ({ isDesktop, routes, userInfo, membershipInfo }) => {
  const { flags } = useContext(GlobalNavInternalContext);
  const { ordersCtaFF: ordersCta, webRatingsAndReviewInGlobalNavDropDownFF: webRRDropDownFF } =
    flags;

  const displayedRoutes = formatRoutes(
    ordersCta ? replaceInvoiceHistory(routes) : routes,
    userInfo.subscriptionState,
    webRRDropDownFF,
  );

  return isDesktop ? (
    <button className="profile-button" type="button">
      <Avatar image={userInfo.avatar} />
      <SubMenu
        items={displayedRoutes}
        isDesktop
        renderFunction={() => (
          <UserProfileItem userInfo={userInfo} isDesktop membershipInfo={membershipInfo} />
        )}
      />
    </button>
  ) : (
    <div className="user-profile">
      <MenuItem
        key="user-profile"
        item={{
          subRoutes: [...displayedRoutes],
          renderFunction: () => (
            <UserProfileItem userInfo={userInfo} membershipInfo={membershipInfo} />
          ),
        }}
      />
    </div>
  );
};

UserProfile.defaultProps = {
  isDesktop: true,
  routes: [],
  userInfo: {
    firstName: '',
    lastName: '',
    role: '',
    subscriptionState: '',
    subscriptionType: '',
    avatar: null,
  },
};

UserProfile.propTypes = {
  isDesktop: PropTypes.bool,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      route: PropTypes.string,
    }),
  ),
  userInfo: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    role: PropTypes.string,
    subscriptionState: PropTypes.string,
    subscriptionType: PropTypes.string,
    avatar: PropTypes.string,
  }),
};

export default UserProfile;
