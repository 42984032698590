import { getParamFromUrl } from '@fff-web/fff-utilities';
import { formatReferrerQueryStringValues } from './formatFields';
import { toTitleCase, propsToTitleCase } from './toTitleCase';
import { PropertiesProps, TrackInfoProps } from '../../interfaces';

const REFERRER_QUERY_STRING = 'referrer';

const getExperienceField = () => {
  const referrerValue = getParamFromUrl(REFERRER_QUERY_STRING);
  const queryValue = formatReferrerQueryStringValues(referrerValue);
  if (!queryValue) {
    return;
  }
  // eslint-disable-next-line consistent-return
  return toTitleCase(queryValue);
};

const trackInfo = ({
  fffAnalytics,
  properties = {},
  localStorageUserInfo,
}: TrackInfoProps): PropertiesProps => ({
  ...fffAnalytics.sessionInfo,
  experience: properties?.experience ? toTitleCase(properties.experience) : getExperienceField(),
  ...propsToTitleCase(properties),
  fff_uuid: localStorageUserInfo?.user?.uuid,
  is_authenticated: localStorageUserInfo?.loggedIn,
});

export default trackInfo;
