import React, { useState } from 'react';
import Button from '../Button/Button';
import IncrementButton from '../IncrementButton/IncrementButton';
import styles from './UpdateCartButton.module.css';

const UpdateCartButton = ({
  id,
  quantity,
  onAddItem,
  soldOut,
  onRemoveItem,
  isVariantAtPurchaseLimit,
  isRestricted,
}) => {
  const [disableAdd, setDisableAdd] = useState(false);
  const handleClickAdd = async () => {
    setDisableAdd(false);
    const response = await onAddItem('Add To My Cart');
    if (!response.success) {
      setDisableAdd(true);
    }
  };

  if (isRestricted) {
    return (
      <span
        data-tip="This product cannot be shipped to you based on the Shipping Address on file."
        data-for="shipping-restriction"
        style={{ width: 'fit-content' }}
      >
        <Button
          disabled
          text="restricted by shipping"
          theme="disabledSecondary"
          className={styles.socialButton}
        />
      </span>
    );
  }
  if (soldOut && quantity === 0) {
    return <Button disabled text="out of stock" className={styles.socialButton} />;
  }
  if (quantity === 0) {
    return (
      <Button
        text="add to my cart"
        theme="primary"
        className={styles.socialButton}
        onClick={handleClickAdd}
        disabled={disableAdd}
      />
    );
  }
  return (
    <IncrementButton
      id={id}
      quantity={quantity}
      isVariantAtPurchaseLimit={isVariantAtPurchaseLimit}
      onAddItem={onAddItem}
      soldOut={soldOut}
      onRemoveItem={onRemoveItem}
    />
  );
};

export default UpdateCartButton;
