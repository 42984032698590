import request from './request';
import { getEnv } from '../environment';
const env = getEnv();
const { REACT_APP_CUSTOMER_API_URL: customerApi } = env;

interface Box {
  campaignId: number;
  id: number;
  image: string;
  name: string;
  quarter: number;
  shiplistSku: string;
  year: number;
}

interface Campaign {
  endDate: string;
  eventBannerImageUrl: string | null;
  eventDescription: string | null;
  eventVisible: boolean;
  id: number;
  published: boolean;
  selectEndDate: string;
  selectStartDate: string;
  startDate: string;
  surveyId: number;
  title: string;
}

interface Season {
  customizeCampaignId: number;
  id: number;
  periods: any[];
  seasonCode: string;
  seasonDisplay: string;
  seasonDisplayOrigin: string;
}

interface SeasonalApiResponse {
  box: Box;
  campaign: Campaign;
  season: Season;
}

export const getSeasonal = async (): Promise<SeasonalApiResponse> => {
  return request({
    path: `${customerApi}`,
  });
};
