import React from 'react';
import FFFErrorBoundary from '@fff-web/fff-ui-shared/FFFErrorBoundary';

const ErrorBoundary = ({ children }) => {
  return (
    <FFFErrorBoundary
      fallbackUI={
        <h3
          style={{
            color: 'lightcoral',
            textAlign: 'center',
            border: '1px solid lightcoral',
            padding: 10,
          }}
        >
          Something went wrong.
        </h3>
      }
    >
      {children}
    </FFFErrorBoundary>
  );
};

export default ErrorBoundary;
