import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import useToggle from '../../hooks/useToggle';
import './CollapsiblePanel.css';

const CollapsiblePanel = ({ initialValue, title, screenMode, children }) => {
  const [height, setHeight] = useState();
  const [isOpen, setOpen, , handleToggle] = useToggle(initialValue);
  const bodyRef = useRef();
  const isDesktop = screenMode === 'desktop';

  useEffect(() => {
    if (isDesktop) {
      setHeight(null);
      setOpen();
    } else {
      setHeight(bodyRef.current.clientHeight);
    }
  }, [children, screenMode, isDesktop]);

  const handleOpen = () => {
    if (!isDesktop) {
      handleToggle();
    }
  };

  return (
    <div className={cx('collapsible-panel', screenMode, { collapsed: !isOpen })}>
      {title && (
        <div
          className="collapsible-panel__header"
          role="button"
          tabIndex="-1"
          onClick={handleOpen}
          onKeyPress={handleOpen}
        >
          <h4>{title}</h4>
        </div>
      )}
      <div
        className="collapsible-panel__body"
        style={{ maxHeight: isOpen ? height || undefined : 0 }}
        ref={bodyRef}
      >
        {children}
      </div>
    </div>
  );
};

CollapsiblePanel.defaultProps = {
  screenMode: 'mobile',
  initialValue: true,
};

CollapsiblePanel.propTypes = {
  screenMode: PropTypes.string,
  initialValue: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
};

export default CollapsiblePanel;
