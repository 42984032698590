import get from 'lodash/get';
import request from './request';

export async function fetchCredits(env) {
  const endpoint = 'api/1/credits/campaigns/default';
  // TODO, delete try useless on this file
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await request(endpoint, 'GET', env);
    return response;
  } catch (err) {
    throw err;
  }
}

export async function fetchInvoicePreview(env, cartId) {
  const requestUrl = `api/1/invoices/preview/carts/${cartId}`;
  return request(requestUrl, 'POST', env);
}
