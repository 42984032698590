import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { getTheme } from './buttonLinkCssProperties';

export type ButtonLinkType = 'primary' | 'black' | 'gray';

type ButtonLinkProps = {
  label: string;
  onClick?: () => void;
  type?: ButtonLinkType;
  disabled?: boolean;
  bold?: boolean;
  fontSize?: number;
  permaUnderline?: boolean;
};

type FFFButtonLinkProps = {
  onClick?: () => void;
  disabled?: boolean;
  bold?: boolean;
  fontSize?: number;
  permaUnderline?: boolean;
};

const FFFButtonLink = styled.button.attrs((props) => ({
  disabled: props.disabled,
  onClick: props.onClick ? props.onClick : () => null,
}))<FFFButtonLinkProps>((props) => ({
  cursor: 'pointer',
  padding: 0,
  fontFamily: "'Nunito Sans', sans-serif;",
  lineHeight: 1.5,
  letterSpacing: 'normal',
  fontSize: props.fontSize || 16,
  border: 'none',
  background: '#ffffff',
  textDecoration: props.permaUnderline ? 'underline' : 'none',
  fontWeight: props.bold ? 'bold' : 'normal',
  ...props.theme,
  '&:hover': {
    textDecoration: 'underline',
    ...props.theme['&:hover'],
  },
  '&:active': {
    textDecoration: 'none',
    ...props.theme['&:active'],
  },
}));

const ButtonLink: React.FunctionComponent<ButtonLinkProps> = ({
  label,
  onClick,
  type,
  disabled,
  bold,
  fontSize,
  permaUnderline,
}) => (
  <ThemeProvider theme={getTheme(type || 'primary')}>
    <FFFButtonLink
      onClick={onClick}
      disabled={disabled}
      bold={bold}
      fontSize={fontSize}
      permaUnderline={permaUnderline}
    >
      {label}
    </FFFButtonLink>
  </ThemeProvider>
);

export default ButtonLink;
