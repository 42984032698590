import React from 'react';

import { Banner } from './components/Banners/Banner';
import Header from './components/Header';
import NavToast from './components/Toast/NavToast';
import MobileWebBanner from './components/MobileWebBanner';

const GlobalNavContent = () => (
  <>
    <MobileWebBanner />
    <Banner />
    <Header />
    <NavToast loggedIn />
  </>
);

export default GlobalNavContent;
