import React, { useEffect } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { track, trackPageLoad, identify } from '@fff-web/fff-analytics';
import { MyBoxStatus, useMyBoxContext } from '@fff-web/fff-box';
import { useUserContext } from '@fff-web/fff-utilities';
import { showSuccessToast } from '@fff-web/fff-ui-shared/Toast';
import { Banner } from '@fff-web/cms';
import Layout from '../../components/Layout';
import NotFoundError from '../NotFoundError';
import InvitePopup from '../../components/InvitePopup';
import InviteBanner from '../../components/InviteBanner';
import useWebCards from './hooks/useWebCards';
import useScreeMode from '../../hooks/useScreenMode';
import useSeasonalTimelines from './hooks/useSeasonalTimelines';
import useInviteTotal from './hooks/useInviteTotal';
import useShowInvitePopup from './hooks/useShowInvitePopup';
import useLaunchDarkly from '../../hooks/useLaunchDarkly';
import useHasUpcomingBox from '../../hooks/useHasUpcomingBox';
import WebCards from '../../components/WebCards';
import SeasonalTimeline from '../../components/SeasonalTimeline';
import QuickLinks from '../../components/QuickLinks';
import { isNotSubscribedStatus, isExpired, isHomepageLoading } from './helpers';
import { getLocalStorage, setLocalStorage } from '../../utils/localStorageHelpers';
import './Homepage.css';

const VIEWED = 'Experiment Viewed';

const Homepage = ({ flags, ldClient, test }) => {
  const { userInfo } = useUserContext();
  const { myBox } = useMyBoxContext();
  useLaunchDarkly(ldClient, userInfo);
  const screenMode = useScreeMode();
  const webCards = useWebCards();
  const seasonalTimelines = useSeasonalTimelines();
  const isLoading = isHomepageLoading({
    myBox,
    webCards,
    seasonalTimelines,
  });
  const inviteTotal = useInviteTotal(flags.homepageCallsNewInvitationApi);
  const { showInvitePopup, stateDispatch } = useShowInvitePopup('invite_popup_exp');
  const closeInvitePopup = () => {
    const invitePopupExpDate = new Date();
    invitePopupExpDate.setDate(invitePopupExpDate.getDate() + 7);
    setLocalStorage('invite_popup_exp', invitePopupExpDate);
    stateDispatch({ type: 'REQUEST_SHOW_INVITE_POPUP_FAIL' });
    document.body.style.overflow = 'auto';
  };
  const { hasIntroProduct } = useHasUpcomingBox();
  const showMyBoxStatus =
    Object.keys(myBox.data).length > 0 && !hasIntroProduct && userInfo?.user?.role !== 'CLUB';

  useEffect(() => {
    if (flags && Object.keys(flags).length > 0 && !test) {
      try {
        track({
          trackName: VIEWED,
          properties: {
            experimentName: 'Homepage Rebuild Launch',
            variationName: 'Challenger',
          },
        }).then();
        trackPageLoad({
          pathname: window.location.pathname,
          properties: {
            box_status: myBox.data && myBox.data.status ? myBox.data.status.currentOrderStep : '',
          },
        }).then();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
  }, [flags]);

  useEffect(() => {
    if (!flags.hideSgtfBannerAndPopup && showInvitePopup && inviteTotal.data > 0) {
      document.body.style.overflow = 'hidden';
    }
  }, [flags.hideSgtfBannerAndPopup, showInvitePopup, inviteTotal]);

  useEffect(() => {
    if (
      getLocalStorage('show_membership_reminder_banner') &&
      getLocalStorage('show_membership_reminder_banner') !== null
    ) {
      showSuccessToast('We’ll remind you 5 days before your membership begins.');
      setLocalStorage('show_membership_reminder_banner', null);
    }
  }, []);

  useEffect(() => {
    identify({
      traits: {
        planCode: userInfo?.user?.planCode,
      },
    });
  }, [userInfo]);

  if (flags.newHomepage === false) {
    return <NotFoundError />;
  }
  const onClickBannerCTA = () => {
    window.location.href = '/shop';
  };
  if (flags.newHomepage === true) {
    return (
      <div className="homepage">
        {!flags.hideSgtfBannerAndPopup && inviteTotal.data > 0 && (
          <InviteBanner inviteTotal={inviteTotal.data} />
        )}
        <Layout isLoading={isLoading} redirectIfNoAuth>
          <div className="wrapperBanner">
            <Banner name="homepage" themeColor="light" onClickCTA={onClickBannerCTA} />
          </div>
          {!flags.hideSgtfBannerAndPopup && showInvitePopup && inviteTotal.data > 0 && (
            <InvitePopup closeInvitePopup={closeInvitePopup} />
          )}
          {Object.keys(seasonalTimelines.data).length > 0 && (
            <SeasonalTimeline
              isExpired={isExpired(myBox, userInfo)}
              isNotSubscribed={isNotSubscribedStatus(myBox.data.status, userInfo)}
              data={seasonalTimelines.data}
              screenMode={screenMode}
              subscriptionType={userInfo?.user?.subscriptionType}
              subscriptionState={userInfo?.user?.subscriptionState}
              role={userInfo?.user?.role}
            />
          )}
          {showMyBoxStatus && <MyBoxStatus RightContent={QuickLinks} flags={flags} />}
          {!webCards.fail && Object.keys(webCards.data).length > 0 ? (
            <WebCards data={webCards.data} screenMode={screenMode} />
          ) : (
            <div className="web-cards_placeholder" />
          )}
        </Layout>
      </div>
    );
  }
  //This is on purpose since the feature flags is completed asynchronously and may be undefined.
  return null;
};

export default withLDConsumer()(Homepage);
