// eslint-disable-next-line import/prefer-default-export
export function getLDClientId(env) {
  switch (env) {
    case 'prod':
      return '5d433efbd97f7506c59300db';
    case 'qa':
      return '5d433edf90809a06bf35929b';
    case 'dev':
      return '5d433e3466637406b0232ac0';
    // DEFAULT RETURNS DEV
    default:
      return '5d433e3466637406b0232ac0';
  }
}
