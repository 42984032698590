import React from 'react';
import PropTypes from 'prop-types';

import OptimizelyProvider from '../../context/OptimizelyProvider';
import { ProspectNavProvider } from '../../context/ProspectNavContext';
import ProspectGlobalNavContent from './ProspectGlobalNavContent';
import { GROWTH_GLOBAL_NAV } from '../../utils/featureFlagVariants/growth-global-nav';

const ProspectGlobalNav = ({ env, ...props }) => (
  <OptimizelyProvider>
    <ProspectNavProvider state={{ env, ...props }}>
      <ProspectGlobalNavContent />
    </ProspectNavProvider>
  </OptimizelyProvider>
);

ProspectGlobalNav.propTypes = {
  env: PropTypes.string,
  variationKey: PropTypes.string,
  labelCTA: PropTypes.string,
  handleCTA: PropTypes.func.isRequired,
  showCart: PropTypes.bool,
  isLoginPage: PropTypes.bool,
};

ProspectGlobalNav.defaultProps = {
  env: '',
  variationKey: GROWTH_GLOBAL_NAV.VARIATION_A,
  labelCTA: '',
  showCart: false,
  isLoginPage: false,
};

export default ProspectGlobalNav;
