import React from 'react';

import './css/fontawesome.min.css';
import './css/brands.min.css';
import './css/light.min.css';
import './css/regular.min.css';
import './css/solid.min.css';

const FontAwesome: React.FC = () => <div />;

export default FontAwesome;
