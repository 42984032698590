import { getEnv } from '../environment';
import request from './request';

type NewMemberState =
  | 'AWAITING_ADDRESS'
  | 'CUSTOMIZE_EDUCATION'
  | 'PERSONAL_SURVEY'
  | 'CUSTOMIZING'
  | 'USER_ONBOARDING_CUSTOMIZING'
  | 'XSELLS'
  | 'USER_ONBOARDING_XSELLS'
  | 'CONFIRMATION'
  | 'USER_COMPLETED'
  | 'READY_FOR_SALE'
  | 'USER_ONBOARDING_1'
  | 'USER_ONBOARDING_2'
  | 'USER_ONBOARDING_3'
  | 'USER_ONBOARDING_4'
  | 'ACTIVE_CHECKOUT'
  | 'PASSIVE_CHECKOUT'
  | 'TIMER_COMPLETED';

export const updateNewMemberState = async (
  shopUserId: string | number,
  newMemberState: NewMemberState,
  questionId?: string | number | null,
  isReactivationUser = false,
) => {
  const currentURL = new URL(window.location.href);
  currentURL.searchParams.delete('ttclid');
  const body = {
    userId: Number(shopUserId),
    newMemberState,
    lastVisitedPage: currentURL.href,
    // TODO: fix this https://fffptc.atlassian.net/browse/WEB-3851
    timerType: isReactivationUser ? 'REACTIVATION' : 'NEW_MEMBER',
    ...(questionId ? { lastVisitedCustomizeQuestionId: questionId } : {}),
  };
  return request({
    path: `${getEnv().REACT_APP_NEW_MEMBER_API_URL}/new-members/timer/self`,
    method: 'PATCH',
    body: JSON.stringify(body),
    json: false,
  });
};
