import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  createRoutes,
  chosenRouteBaseOnVariation,
  variationMapMobile,
} from '../../utils/prospectGlobalNav';
import { ProspectNavContext } from '../../context/ProspectNavContext';
import styles from './MobileMenu.module.css';
import { ProspectNavItems } from './ProspectNavItems';

const MobileMenu = ({ isOpenMenu }) => {
  const {
    env,
    mainProps: { variationKey, isLoginPage },
  } = useContext(ProspectNavContext);
  const baseUrl = env.REACT_APP_BASE_URL;
  const loginUrl = env.REACT_APP_LOGIN_BASE_URL;

  const routes = chosenRouteBaseOnVariation(
    createRoutes(baseUrl, loginUrl, isLoginPage),
    variationKey,
    variationMapMobile,
  );

  const navigationMobile = classNames(styles.mobileMenu, {
    [styles.showMenu]: isOpenMenu,
  });

  return (
    <nav data-testid="mobile-menu" className={navigationMobile}>
      <ul role="menu">
        <ProspectNavItems routes={routes} />
      </ul>
    </nav>
  );
};

MobileMenu.propTypes = {
  isOpenMenu: PropTypes.bool.isRequired,
};

export default MobileMenu;
