import React from 'react';
import spacetime from 'spacetime';
import { updateNewMemberState } from '@fff-web/fff-utilities';
import OnboardingModal, { OnboardingModalACTIONS } from '@fff-web/fff-ui-shared/OnboardingModal';
import { useInternalContext } from '../../../context/InternalContext';
import styles from './CartOnboarding.module.css';

// TODO: move all states to Utilities and use accross all apps
export const NEW_MEMBER_STATE = {
  AWAITING_ADDRESS: 'AWAITING_ADDRESS',
  PERSONAL_SURVEY: 'PERSONAL_SURVEY',
  CUSTOMIZING: 'CUSTOMIZING',
  USER_ONBOARDING_CUSTOMIZING: 'USER_ONBOARDING_CUSTOMIZING',
  XSELLS: 'XSELLS',
  USER_ONBOARDING_XSELLS: 'USER_ONBOARDING_XSELLS',
  CONFIRMATION: 'CONFIRMATION',
};

export const CartOnboarding = ({
  campaignData,
  newMemberOnboardingFF,
  userId,
  saleItemsCount,
  isNewMember,
  newMemberState,
  onNewMemberStateFetched,
}) => {
  const { isNewMemberBoostsPage, isNewMemberCustomize } = useInternalContext();

  const handleOnboardingCallback = async ({ action }) => {
    if (action === OnboardingModalACTIONS.CLOSE) {
      const newState =
        newMemberState === NEW_MEMBER_STATE.CUSTOMIZING
          ? NEW_MEMBER_STATE.USER_ONBOARDING_CUSTOMIZING
          : NEW_MEMBER_STATE.USER_ONBOARDING_XSELLS;
      // Opitimistically update our context with the new state to avoid re-opening the tutorial modal
      onNewMemberStateFetched(newState);
      await updateNewMemberState(userId, newState);
    }
  };

  const showOnboarding = !!(
    newMemberOnboardingFF &&
    ((isNewMemberBoostsPage && newMemberState === NEW_MEMBER_STATE.XSELLS) ||
      (isNewMemberCustomize && newMemberState === NEW_MEMBER_STATE.CUSTOMIZING)) &&
    saleItemsCount
  );

  return (
    <>
      {campaignData && isNewMember && (
        <OnboardingModal
          hideBackButton
          disableOverlayClose
          disableScrolling
          stepIndex={0}
          steps={getOnboardingSteps(campaignData.edit.endDate)}
          spotlightPadding={5}
          run={showOnboarding}
          callback={handleOnboardingCallback}
        />
      )}
    </>
  );
};

const getOnboardingSteps = (campaignEndDate) => {
  const time = spacetime(campaignEndDate, 'UTC').goto('America/Los_Angeles');
  const formattedTime = time.format('{month} {date-ordinal} at {hour}:{minute-pad}{ampm} PST');

  return [
    {
      target: '#cart',
      title: (
        <>
          <span className={styles.centerAlign}>
            <i className="icon far fa-check-circle onboardingGreenIcon" />
            &nbsp;
            <span>ADDED TO YOUR BOX!</span>
          </span>
          <i className="icon far fa-arrow-up onboardingRedIcon" />
        </>
      ),
      content: (
        <>
          All add-on items will be automatically processed with your box. Add or remove items any
          time before <strong>{formattedTime}</strong>.
        </>
      ),
      placement: 'bottom-end',
      disableBeacon: true,
      hideCloseButton: true,
      locale: {
        close: 'GOT IT!',
      },
    },
  ];
};
