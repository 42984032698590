import { CountryLocationApiResponse } from '../../interfaces/ICountryLocationApiResponse';
import request from './request';
import { getEnv } from '../environment';

const env = getEnv();
const { REACT_APP_USER_URL: userApi, REACT_APP_GROWTH_URL: growthApi } = env;

export const getAddresses = () => request({ path: `${userApi}/self/addresses` });

export const getAddress = (id: string) => request({ path: `${userApi}/self/addresses/${id}` });

export const updateAddress = (payload: any, id: string) =>
  request({
    path: `${userApi}/self/addresses/${id}`,
    method: 'PUT',
    body: JSON.stringify(payload),
  });

export const addAddress = (payload: any) =>
  request({
    path: `${userApi}/self/addresses`,
    method: 'POST',
    body: JSON.stringify(payload),
  });

export const removeAddress = (addressId: string) =>
  request({
    path: `${userApi}/self/addresses/${addressId}`,
    method: 'DELETE',
  });

export const verifyAddress = (payload: any) =>
  request({
    path: `${growthApi}/location/verify-address`,
    method: 'POST',
    body: JSON.stringify(payload),
  });

export const getCountryIsoCode = (): Promise<CountryLocationApiResponse> =>
  request({
    path: `${growthApi}/location/country-by-ip`,
  });
