import { EnvUtils } from '@fff-web/fff-utilities';

export const getEnv = (env) => {
  if (
    process.env &&
    process.env.REACT_APP_OPTIMIZELY &&
    process.env.REACT_APP_MOBILE_DYNAMIC_LINK_BASE_URL &&
    process.env.REACT_APP_NAVIGATOR_URL &&
    process.env.REACT_APP_BASE_URL &&
    process.env.REACT_APP_TIMER_URL &&
    process.env.REACT_APP_NEW_MEMBER_CAMPAIGN_URL &&
    process.env.REACT_APP_NEW_MEMBER_API_URL &&
    process.env.REACT_APP_ENVIRONMENT &&
    process.env.REACT_APP_LOGIN_BASE_URL
  ) {
    return { ...process.env, name: process.env.REACT_APP_ENVIRONMENT };
  }

  const enF = env || EnvUtils.getEnvUsingWindowLocation();

  if (enF === 'local') {
    return { ...getEnv('dev'), name: 'local' };
  }

  if (enF === 'prod') {
    return {
      name: 'prod',
      REACT_APP_OPTIMIZELY: 'MRGJTqny6nXVLVsM7LZ7u',
      REACT_APP_MOBILE_DYNAMIC_LINK_BASE_URL: 'https://fabfitfun.page.link',
      REACT_APP_NAVIGATOR_URL: 'https://universal-navigation-api.fabfitfun.com',
      REACT_APP_BASE_URL: 'https://fabfitfun.com',
      REACT_APP_TIMER_URL: 'https://universal-navigation-api.fabfitfun.com/timer',
      REACT_APP_NEW_MEMBER_CAMPAIGN_URL:
        'https://customize-api.fabfitfun.com/customize/new-members/self/campaign-state',
      REACT_APP_NEW_MEMBER_API_URL: 'https://new-member-api.fabfitfun.com',
      REACT_APP_BANNER_URL: 'https://homepage-api.fabfitfun.com/megaphone-banners/current',
      REACT_APP_ADDONS_URL: 'https://fabfitfun.com/add-ons',
      REACT_APP_EDIT_URL: 'https://fabfitfun.com/edit',
      REACT_APP_NEW_MEMBER_API_BASE: 'https://new-member-api.fabfitfun.com',
      REACT_APP_LOGIN_BASE_URL: 'https://login.fabfitfun.com',
    };
  }

  return {
    name: enF,
    REACT_APP_OPTIMIZELY: 'NbWDYMSCwJeD5ZMu8Ne5L',
    REACT_APP_MOBILE_DYNAMIC_LINK_BASE_URL: 'https://fffdev.page.link',
    REACT_APP_NAVIGATOR_URL: `https://universal-navigation-api.fff-${enF}.com`,
    REACT_APP_BASE_URL: `https://fff-${enF}.com`,
    REACT_APP_TIMER_URL: `https://universal-navigation-api.fff-${enF}.com/timer`,
    REACT_APP_NEW_MEMBER_CAMPAIGN_URL: `https://customize-api.fff-${enF}.com/customize/new-members/self/campaign-state`,
    REACT_APP_NEW_MEMBER_API_URL: `https://new-member-api.fff-${enF}.com`,
    REACT_APP_BANNER_URL: `https://homepage-api.fff-${enF}.com/megaphone-banners/current`,
    REACT_APP_ADDONS_URL: `https://fff-${enF}.com/add-ons`,
    REACT_APP_EDIT_URL: `https://fff-${enF}.com/edit`,
    REACT_APP_NEW_MEMBER_API_BASE: `https://new-member-api.fff-${enF}.com`,
    REACT_APP_LOGIN_BASE_URL: 'https://login.fff-dev.com',
  };
};
