import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { createTrackingData } from '../../utils/prospectGlobalNav';
import { ProspectNavContext } from '../../context/ProspectNavContext';
import { constructUrlWithParams } from '../../utils/navigation';

import styles from './ProspectHeaderContainer.module.css';

export const ProspectNavItems = ({ routes }) => {
  const { segmentTrackingData } = useContext(ProspectNavContext);
  const linkClass = styles.link;

  return routes.map(({ label, url, onClick }) => {
    const handleClick = async (e) => {
      e.preventDefault();

      if (onClick) {
        onClick();
        return;
      }
      await createTrackingData({
        ctaName: label,
        segmentTrackingData,
      });

      const newURL = constructUrlWithParams(url);
      window.location.href = newURL;
    };

    return (
      <li className={styles.listItem} key={`${label}`} role="menuitem">
        {onClick ? (
          <button type="button" onClick={handleClick} className={linkClass}>
            {label}
          </button>
        ) : (
          <a href={url} onClick={handleClick} className={linkClass}>
            {label}
          </a>
        )}
      </li>
    );
  });
};

const routePropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    url: PropTypes.string,
    onClick: PropTypes.func,
  }),
);

ProspectNavItems.propTypes = {
  routes: routePropTypes.isRequired,
};
