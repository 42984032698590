import React from 'react';
import classNames from 'classnames';

import styles from './CartButton.module.css';

const CartButton = ({ itemsCount, toggleCart }) => (
  <button
    id="cart"
    className={classNames(styles.container, 'cart-button')}
    onClick={toggleCart}
    type="button"
    data-dd-action-name="open/close cart button"
  >
    {itemsCount > 0 && itemsCount <= 99 && <span>{itemsCount}</span>}
    {itemsCount > 99 && <span className={styles.maxCount}>99+</span>}
    <i className="fal fa-shopping-cart" />
  </button>
);

export default CartButton;
