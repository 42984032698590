import { hostname } from './hostname';
import { GetEnvProps } from '../../interfaces';

const getEnv = (): GetEnvProps => {
  if (hostname().includes('fabfitfun')) {
    return {
      name: 'prod',
      originUrl: 'fabfitfun.com',
      segmentToken: '7kbHDCgVdd',
    };
  }

  if (hostname().includes('fff-staging')) {
    return {
      name: 'staging',
      originUrl: 'fff-staging.com',
      segmentToken: 'vVhAGbpFsr2EJsYiB1e4y0IMnzMl10T8',
    };
  }

  if (hostname().includes('fff-qa')) {
    return {
      name: 'qa',
      originUrl: 'fff-qa.com',
      segmentToken: '13meRIwJgrTiyjmcHpuNKjg4DztAEF8L',
    };
  }

  return {
    name: 'dev',
    originUrl: 'fff-dev.com',
    segmentToken: 'hNqaG11W3uumnIjKGU7cwCx6vduBrmc8',
  };
};

export { getEnv };
