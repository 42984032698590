import React from 'react';
import EmptyState from '../EmptyState/EmptyState';
import { useInternalContext } from '../../../context/InternalContext';

const EmptyCart = ({ campaignType, closeCart, isOnOwnPage, isPublicShopping }) => {
  const { hostname } = useInternalContext();

  const buttons = {
    SHOP: {
      name: isPublicShopping ? 'Shop now' : 'Go to the shop',
      page: 'shop',
    },
    SEASON: {
      name: 'Shop the sale',
      page: 'add-ons',
    },
    EDIT: {
      name: 'Shop the sale',
      page: 'edit',
    },
  };

  const button = buttons[campaignType];
  const buttonAction = isOnOwnPage
    ? closeCart
    : () => {
        window.location.assign(`https://${hostname}/${button.page}`);
      };

  return (
    <EmptyState
      icon="fal fa-box-open"
      title="Your Cart is Empty"
      description="Treat yourself and start shopping!"
      action={buttonAction}
      cta={button.name}
    />
  );
};

export default EmptyCart;
