const isString = (s: any): boolean => typeof s === 'string';

const formatString = (s: string): string => {
  if (!isString(s)) return '';

  return s.trim().toLocaleLowerCase();
};

const formatRole = (role: string): string | undefined => {
  const formattedRole = formatString(role);
  switch (formattedRole) {
    case 'select': {
      return 'select';
    }
    case 'customer': {
      return 'customer';
    }
    case 'expired': {
      return 'customer';
    }
    case 'trial': {
      return 'customer';
    }
    default: {
      return undefined;
    }
  }
};

const formatMemberStatus = (subscriptionState: string): string | undefined => {
  const formattedSubscriptionState = formatString(subscriptionState);
  switch (formattedSubscriptionState) {
    case 'active': {
      return 'Active';
    }
    case 'canceled': {
      return 'Voluntary Cancel';
    }
    case 'cancelled_with_zero_boxes': {
      return 'Voluntary Cancel';
    }
    case 'cancelledwithzeroboxes': {
      return 'Voluntary Cancel';
    }
    case 'future': {
      return 'Voluntary Cancel';
    }
    case 'expired': {
      return 'Expired';
    }
    default: {
      return undefined;
    }
  }
};

const formatPlanCode = (planCode: string): string | undefined => {
  const lowerPlanCode = formatString(planCode);
  switch (lowerPlanCode) {
    case 'fffvip': {
      return 'VIP';
    }
    case 'fffvipa': {
      return 'VIPA';
    }
    case 'ffftrial': {
      return 'Trial';
    }
    case 'fffsocial': {
      return 'FFFSOCIAL';
    }
    default: {
      return undefined;
    }
  }
};

const formatReferrerQueryStringValues = (value: string): string => {
  const referrerQueryString = {
    my_account: 'MY ACCOUNT',
    global_footer: 'FOOTER',
    footer_link: 'FOOTER LINK',
    footer: 'FOOTER',
    my_box: 'MY BOX',
    homepage: 'HOMEPAGE',
    customize: 'CUSTOMIZE',
    global_nav: 'GLOBAL NAV',
    global_banner: 'GLOBAL BANNER',
    homepage_popup: 'HOMEPAGE POPUP',
    cancel_flow: 'CANCEL FLOW',
    my_account_cta: 'MY ACCOUNT CTA',
  };
  return referrerQueryString[value as keyof typeof referrerQueryString];
};

export { formatRole, formatMemberStatus, formatPlanCode, formatReferrerQueryStringValues };
