const isEqualObject = (object1: any, object2: any): boolean => {
  if (!object1 && !object2) return true;

  if ((!object1 && object2) || (object1 && !object2)) return false;

  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) return false;

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);

    if ((areObjects && !isEqualObject(val1, val2)) || (!areObjects && val1 !== val2)) return false;
  }

  return true;
};

const isObject = (object: object) => object != null && typeof object === 'object';

export { isEqualObject, isObject };
