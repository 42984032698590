import React from 'react';

import Button from '../../../shared/components/Button/Button';
import styles from './EmptyState.module.css';

const EmptyState = ({ icon, title, description, cta, action, secondDescription }) => (
  <div className={styles.container}>
    {icon && <i className={icon} />}
    <p className={styles.title}>{title}</p>
    {description && <p className={styles.description}>{description}</p>}
    {secondDescription && <p className={styles.description}>{secondDescription}</p>}
    <Button onClick={action} text={cta} theme="primary" style={{ marginTop: 24 }} />
  </div>
);

export default EmptyState;
