import React, { useState } from 'react';

import './InviteBanner.css';
import sgtfBox from './starter-box.png';
import { HOMEPAGE_EXPERIENCE } from '../../utils/constants';
import { trackInviteStarted } from '../../utils/track';

const INVITE_CTA_NAME = 'Starter Boxes';

const InviteBanner = ({ inviteTotal }) => {
  const [showBanner, setShowBanner] = useState(true);
  const handleOnClick = () => {
    trackInviteStarted(INVITE_CTA_NAME, HOMEPAGE_EXPERIENCE);
  };
  return (
    <>
      {showBanner && (
        <div className="mask banner">
          <div className="box-copy">
            <img src={sgtfBox} alt="" />
          </div>
          <div className="all-text">
            <h3 className="share-a-Taste-of-Fab">Know Someone Who Would Love FabFitFun?</h3>
            <p className="you-have-3-Starter-B">
              You have{' '}
              <a href="/invite-friends" onClick={handleOnClick} className="banner-link">
                {inviteTotal} Starter Boxes
              </a>{' '}
              to send!{' '}
              <span id="secondLine">Send a complimentary box now - offer expires soon.</span>
            </p>
          </div>
          <button
            type="button"
            className="times"
            onClick={() => {
              setShowBanner(false);
            }}
          >
            <i className="fal fa-times" />
          </button>
        </div>
      )}
    </>
  );
};

export default InviteBanner;
