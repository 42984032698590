import React from 'react';
import Button from '../Button';
import styles from './IntroProductModal.module.css';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getIntroProductModalCopy = (planPrice: number) => ({
  title: 'Members Save More',
  subtitle: `Become a member and enjoy up to 70% off premium lifestyle products & brands. $${planPrice}/season unlocks:`,
  list: [`Our Seasonal Box or $${planPrice} in credits to shop*`, 'Member pricing & sales'],
  imgUrl: 'https://scontent01.fabfitfun.com/ecom/images/2023/11/mwp+hero+m.jpg',
  button: 'OK, GOT IT',
  disclaimer:
    // eslint-disable-next-line max-len
    '*Seasonal Box includes up to 6 full size products valued at up to $300. Swap your box for credits to shop. Restrictions apply.',
  link: 'https://support.fabfitfun.com/?a=What-is-the-Swap-for-Credit-program---id--JAruIHy-RPiYJUvdl5dv4w',
  linkText: 'Learn more.',
});

type IntroProductModalContentProps = {
  onClose: () => void;
  isMobile: boolean;
  planPrice: number;
};

const IntroProductModalContent: React.FunctionComponent<IntroProductModalContentProps> = ({
  onClose,
  isMobile,
  planPrice,
}) => {
  const { title, subtitle, list, imgUrl, button, disclaimer, link, linkText } =
    getIntroProductModalCopy(planPrice);

  return (
    <div className={styles.container}>
      {isMobile && <img src={imgUrl} alt="beauty-products" />}
      <div className={styles.textContainer}>
        <h2>{title}</h2>
        {!isMobile && <img src={imgUrl} alt="beauty-products" />}
        <p className={styles.subtitle}>{subtitle}</p>
        <ul>
          {list.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
        <Button onClick={onClose} variation="black">
          {button}
        </Button>
        <p className={styles.disclaimer}>
          {disclaimer}{' '}
          <a href={link} target="_blank" rel="noreferrer">
            {linkText}
          </a>
        </p>
      </div>
    </div>
  );
};

export default IntroProductModalContent;
