import React from 'react';
import Toast from '@fff-web/fff-ui-shared/Toast';
import useExpirationToast from '../../hooks/useExpirationToast';

const NavToast = ({ loggedIn }) => {
  const { snoozeToast } = useExpirationToast(loggedIn);

  return <Toast onCloseButton={snoozeToast} />;
};

export default NavToast;
