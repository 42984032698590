// @flow
import { useEffect, useState } from 'react';
import { FFFStorage } from '@fff-web/fff-utilities';
import { getShippingAddress } from '../services';

const emptyShippingAddress = () => ({
  address1: '',
  address2: '',
  city: '',
  state: '',
  postalCode: '',
  country: '',
});

const addressDurationInMs = 10 * 60 * 1000; //10 minutes;
const addressKey = 'address-with-login-Info';
const getAddressFromStorage = () => {
  const addressWithLoginInfo = FFFStorage.getFromStorage({ key: addressKey });
  return addressWithLoginInfo || emptyShippingAddress();
};
export function useShippingAddress(callUser) {
  const [shippingAddress, setShippingAddress] = useState({ ...getAddressFromStorage() });

  useEffect(() => {
    // eslint-disable-next-line consistent-return
    async function setShippingAddressState() {
      const savedAddress = FFFStorage.getFromStorage({ key: addressKey });
      if (savedAddress) {
        return savedAddress;
      }
      try {
        const addressDetailsResponse = await getShippingAddress();
        let addressWithLoginInfo = { ...emptyShippingAddress() };

        if (addressDetailsResponse.ok) {
          const addressDetails = await addressDetailsResponse.json();
          addressWithLoginInfo = {
            ...(callUser ? addressDetails : emptyShippingAddress()),
          };
          FFFStorage.setToStorage({
            key: addressKey,
            value: addressWithLoginInfo,
            durationInMs: addressDurationInMs,
          });
        } else {
          FFFStorage.removeFromStorage({ key: addressKey });
        }
        setShippingAddress(addressWithLoginInfo);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        FFFStorage.removeFromStorage({ key: addressKey });
      }
    }

    if (callUser === true) {
      setShippingAddressState();
    }
  }, [callUser]);

  return shippingAddress;
}
