import React from 'react';

import styles from './ShippingProgressBar.module.css';

const ShippingProgressBar = ({ estimatedTotal, totalRequired }) => {
  const percentage = totalRequired > 0 ? Math.floor((estimatedTotal * 100) / totalRequired) : 100;
  return (
    <div className={styles.container}>
      <div className={styles.progressBarBackground}>
        <div
          className={styles.progressBarFill}
          style={{ width: `${percentage}%` }}
          data-testid="shipping-progress-bar-fill"
        />
      </div>
    </div>
  );
};

export default ShippingProgressBar;
