import React from 'react';
import EmptyState from '../EmptyState/EmptyState';
import { useInternalContext } from '../../../context/InternalContext';

const FlashSales = ({ isOnOwnPage, closeCart }) => {
  const { hostname } = useInternalContext();
  const buttonAction = isOnOwnPage
    ? closeCart
    : () => {
        global.window.location = `https://${hostname}/add-ons`;
      };

  return (
    <EmptyState
      icon="fal fa-box"
      title="Add-Ons is Closed"
      description="Find out more about Add-Ons."
      cta="Learn More"
      action={buttonAction}
    />
  );
};

export default FlashSales;
