import React from 'react';
import styles from './SponsoredProductModal.module.css';
import Button from '../../../shared/components/Button/Button';

const copy = {
  sponsored: {
    title: 'Are you sure you want to remove this Free Gift?',
    description: 'Quantities are limited and you might not have an opportunity to add this again!',
  },
  nonSponsored: {
    title: 'Are you sure you want to remove this product?',
    description: "You'll lose your free gift :(",
  },
};

const SponsoredProductModal = ({ closeModal, handleClickContinue, modalType }) => {
  return (
    <div className={styles.modal}>
      {/*eslint-disable-next-line jsx-a11y/control-has-associated-label*/}
      <div
        className={styles.overlay}
        onClick={closeModal}
        role="button"
        onKeyDown={closeModal}
        tabIndex={0}
      />
      <div className={styles.container}>
        <button className="cart-button" type="button" onClick={closeModal}>
          <i className="fas fa-times-circle" />
        </button>
        <h2>{copy[modalType].title}</h2>
        <p>{copy[modalType].description}</p>
        <div className={styles.buttonContainer}>
          <Button
            text="Yes, Remove"
            theme="outline"
            onClick={handleClickContinue}
            style={{ width: '228px' }}
          />
          <Button text="Cancel" theme="primary" onClick={closeModal} style={{ width: '228px' }} />
        </div>
      </div>
    </div>
  );
};

export default SponsoredProductModal;
