import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import OptimizelyProvider from './context/OptimizelyProvider';
import { getEnv } from '../environment';

import { useBootstrapLD } from './hooks/useBootstrapLD';
import GlobalNavInternalContext from './context/GlobalNavInternalContext';
import GlobalNavContent from './GlobalNavContent';
import { UniversalNavigationContext } from './context/UniversalNavigationContext';

const GlobalNav = ({ env, ...props }) => {
  const externalContextData = useContext(UniversalNavigationContext);
  const {
    downloadMobileAppWebBannerFF,
    downloadMobileAppWebBannerReady,
    ordersCtaFF,
    ordersCtaReady,
    webRatingsAndReviewInGlobalNavDropDownFF,
    webRatingsAndReviewInGlobalNavDropDownReady,
    ccToastNotificationFF,
    ccToastNotificationReady,
    newMemberOnboardingFF,
    newMemberOnboardingReady,
  } = useBootstrapLD(env);

  const contextValue = {
    env: getEnv(env),
    mainProps: { env, ...props },
    ...externalContextData,
    flags: {
      downloadMobileAppWebBannerFF,
      downloadMobileAppWebBannerReady,
      ordersCtaFF,
      ordersCtaReady,
      webRatingsAndReviewInGlobalNavDropDownFF,
      webRatingsAndReviewInGlobalNavDropDownReady,
      ccToastNotificationFF,
      ccToastNotificationReady,
      newMemberOnboardingFF,
      newMemberOnboardingReady,
    },
  };

  return (
    <OptimizelyProvider>
      <GlobalNavInternalContext.Provider value={contextValue}>
        <GlobalNavContent />
      </GlobalNavInternalContext.Provider>
    </OptimizelyProvider>
  );
};

GlobalNav.propTypes = {
  env: PropTypes.string,
  hideCart: PropTypes.bool,
};

GlobalNav.defaultProps = {
  env: '',
  hideCart: false,
};

export default React.memo(GlobalNav);
