import React from 'react';
import classNames from 'classnames';
import styles from './button.module.css';

const Button = ({ theme, text, onClick, style, className, disabled }) => {
  return (
    <button
      onClick={onClick}
      type="button"
      disabled={disabled}
      className={classNames(styles.button, styles[theme], className)}
      style={style}
    >
      {text}
    </button>
  );
};

export default Button;
