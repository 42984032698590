import React, { useContext } from 'react';
import ProspectHeader from './ProspectHeader';
import { Banner } from '../Banners/Banner';
import { ProspectNavContext } from '../../context/ProspectNavContext';
import NavToast from '../Toast/NavToast';
import MobileWebBanner from '../MobileWebBanner';

const ProspectGlobalNavContent = () => {
  const {
    mainProps: { isPublicShop },
  } = useContext(ProspectNavContext);

  return (
    <>
      <MobileWebBanner />
      <Banner isPublicShop={isPublicShop} />
      <ProspectHeader />
      <NavToast loggedIn={false} />
    </>
  );
};

export default ProspectGlobalNavContent;
