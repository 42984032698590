import { getEnv } from '../environment';
import request from './request';

export const getCampaigns = async () => {
  return request({
    path: `${getEnv().REACT_APP_ACE_API_URL}/api/1/campaigns/default`,
    method: 'GET',
    json: true,
  });
};

export const getCustomizeCampaign = async ({ newMember }: { newMember: boolean }) => {
  if (newMember) {
    return request({
      path: `${getEnv().REACT_APP_CUSTOMIZE_API_URL}/customize/new-members/self/campaign-state`,
    });
  } else {
    return request({
      path: `${getEnv().REACT_APP_CUSTOMIZE_API_URL}/customize/self/campaign-state`,
    });
  }
};
