import React from 'react';

const HowItWorks = () => (
  <section className="hiw-container">
    <h2 className="rangeme-title">How RangeMe Works</h2>
    <div className="steps">
      <div className="step">
        <div className="step-number">
          <span>1</span>
        </div>
        <h2 className="step-title">Create Profile</h2>
        <p className="step-details">
          Create a product profile. The upload process will help you enter all the key info we need
          to see.
        </p>
      </div>
      <div className="step">
        <div className="step-number">
          <span>2</span>
        </div>
        <h2 className="step-title">Product Matching</h2>
        <p className="step-details" style={{ maxWidth: '291px' }}>
          Your product is matched with the right category buyer. Clever technology ensures your
          assortment is shown to the right person.
        </p>
      </div>
      <div className="step">
        <div className="step-number">
          <span>3</span>
        </div>
        <h2 className="step-title">Buyer Review</h2>
        <p className="step-details" style={{ maxWidth: '259px', marginBottom: '54px' }}>
          Buyers review your products. You will receive notifications once your product has been
          reviewed.
        </p>
      </div>
    </div>
  </section>
);

export default HowItWorks;
