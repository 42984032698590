import React from 'react';
import styles from './CartOverlay.module.css';

const CartOverlay = ({ closeCart }) => (
  //TODO: The divs shouldn't contain functions for onClick or onKeyDown, change this div
  // eslint-disable-next-line jsx-a11y/control-has-associated-label
  <div
    className={styles.container}
    onClick={closeCart}
    role="button"
    onKeyDown={closeCart}
    tabIndex={0}
  />
);

export default CartOverlay;
