import GlobalCart from '@fff-web/fff-cart';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import GlobalNavInternalContext from '../../context/GlobalNavInternalContext';
import useInView from '../../hooks/useInView';
import HamburgerMenuIcon from '../HamburgerMenuIcon';
import NavigationMenu from '../NavigationMenu';
import fffLogo from '../../assets/fff-logo.svg';
import './Header.css';

const HeaderContent = ({
  isLoggedIn,
  isDesktop,
  isNavigationMenuVisible,
  handleToggleNavigationMenuVisible,
  routes,
  userInfo,
}) => {
  const { env, mainProps, newMemberState, onNewMemberStateFetched } =
    useContext(GlobalNavInternalContext);
  const baseUrl = env.REACT_APP_BASE_URL;
  const { isInView, elementRef: leftContainerRef } = useInView();
  const stickyCart = mainProps.stickyCartPage && !isInView;

  return (
    <>
      <div className="header__content">
        {!isDesktop ? (
          <>
            <HamburgerMenuIcon
              isOpen={isNavigationMenuVisible}
              onClick={handleToggleNavigationMenuVisible}
            />
            <a className="fff-logo" href={baseUrl}>
              <img src={fffLogo} alt="logo" />
            </a>
          </>
        ) : (
          <a className="fff-logo" href={baseUrl}>
            <img src={fffLogo} alt="logo" className="trademark" />
          </a>
        )}
        {!isDesktop &&
          (isLoggedIn ? (
            <div className="shopping-cart">
              {!mainProps.hideCart && (
                <GlobalCart
                  {...mainProps}
                  env={env.name}
                  newMemberState={newMemberState}
                  onNewMemberStateFetched={onNewMemberStateFetched}
                />
              )}
            </div>
          ) : (
            <a className="header__join" href={`${baseUrl}/get-the-box/?step=getbox&#getstarted`}>
              JOIN
            </a>
          ))}
      </div>
      {isNavigationMenuVisible && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div className="header__overlay" onClick={handleToggleNavigationMenuVisible} />
      )}
      <div className="left-container" ref={leftContainerRef}>
        <NavigationMenu
          routes={routes}
          isVisible={isNavigationMenuVisible}
          isDesktop={isDesktop}
          isLoggedIn={isLoggedIn}
          userInfo={userInfo}
          stickyCart={stickyCart}
          env={env.name}
          newMemberState={newMemberState}
          onNewMemberStateFetched={onNewMemberStateFetched}
        />
      </div>
    </>
  );
};

HeaderContent.defaultProps = {
  isLoggedIn: false,
  isDesktop: false,
  isNavigationMenuVisible: false,
  handleToggleNavigationMenuVisible: () => null,
  routes: {},
  cartItems: 0,
  userInfo: {},
};

HeaderContent.propTypes = {
  isLoggedIn: PropTypes.bool,
  isDesktop: PropTypes.bool,
  isNavigationMenuVisible: PropTypes.bool,
  handleToggleNavigationMenuVisible: PropTypes.func,
  routes: PropTypes.shape({
    loginState: PropTypes.arrayOf(PropTypes.shape()),
    logoutState: PropTypes.arrayOf(PropTypes.shape()),
  }),
  userInfo: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    role: PropTypes.string,
    subscriptionState: PropTypes.string,
    subscriptionType: PropTypes.string,
    avatar: PropTypes.string,
  }),
  cartItems: PropTypes.number,
};

export default HeaderContent;
