import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { GlobalNav } from '@fff-web/fff-global-nav';
import { GlobalFooter } from '@fff-web/fff-global-footer';
import { Loading } from '../Loading';

const Layout = ({ children, pageTitle, meta, isLoading, redirectIfNoAuth }) => {
  const [hasPermissions, setHasPermissions] = useState(!redirectIfNoAuth);
  useEffect(() => {
    if (redirectIfNoAuth) {
      fetch(process.env.REACT_APP_LOGIN_VERIFY_API, { credentials: 'include' }).then(({ ok }) => {
        if (!ok) {
          const url = `${process.env.REACT_APP_SITE_URL}/get-the-box`;
          const { search, hash } = window.location;

          window.location.replace(`${url}${search}${hash}`);
        } else {
          setHasPermissions(true);
        }
      });
    }
  }, [redirectIfNoAuth]);

  return (
    hasPermissions && (
      <>
        <Helmet>
          <title>{pageTitle}</title>
          {meta &&
            meta.links &&
            meta.links.map(({ href, ...link }) => (
              <link key={href} {...link} href={href} rel="stylesheet" type="text/css" />
            ))}
          {meta &&
            meta.scripts &&
            meta.scripts.map(({ src, ...script }) => <script key={src} {...script} src={src} />)}
        </Helmet>
        <MemoizedGlobalNav />
        {isLoading ? <Loading /> : children}
        <GlobalFooter />
      </>
    )
  );
};

Layout.defaultProps = {
  pageTitle: 'FabFitFun',
  meta: {},
  isLoading: false,
  redirectIfNoAuth: false,
};

Layout.propTypes = {
  pageTitle: PropTypes.string,
  isLoading: PropTypes.bool,
  redirectIfNoAuth: PropTypes.bool,
  meta: PropTypes.shape({
    links: PropTypes.arrayOf(String),
    scripts: PropTypes.arrayOf(String),
  }),
};

export default Layout;

const MemoizedGlobalNav = React.memo(() => <GlobalNav env={process.env.REACT_APP_ENVIRONMENT} />);
