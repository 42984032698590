/* eslint-disable max-len */
import React, { type SVGProps } from 'react';
import Icon from './Icon';

const Times: React.FunctionComponent<SVGProps<SVGSVGElement>> = (props) => (
  <Icon width="12px" height="12px" viewBox="0 0 12 12" {...props}>
    <g id="Page-1" stroke="none" strokeWidth="1">
      <g id="Group-23-Copy-6" transform="translate(0.000000, -5.000000)">
        <path
          d="M2.2133173,16.7108261 L5.99998659,12.9234359 L9.7869992,16.7104828 C10.0060386,16.9294879 10.3614455,16.9294879 10.5808282,16.7104828 L11.4607966,15.8305144 C11.6798017,15.611475 11.6798017,15.2560681 11.4607966,15.0366854 L7.67340637,11.2500161 L11.4604533,7.46300349 C11.6794584,7.24396409 11.6794584,6.88855722 11.4604533,6.66917451 L10.5804849,5.78920605 C10.3614455,5.57020099 10.0060386,5.57020099 9.78665588,5.78920605 L5.99998659,9.57659632 L2.21297398,5.78954938 C1.99393459,5.57054431 1.63852772,5.57054431 1.419145,5.78954938 L0.539519871,6.66917451 C0.320514809,6.88817957 0.320514809,7.24362077 0.539519871,7.46300349 L4.32656681,11.2500161 L0.539519871,15.0370287 C0.320514809,15.2560681 0.320514809,15.611475 0.539519871,15.8308577 L1.41948832,16.7108261 C1.63852772,16.9298312 1.99393459,16.9298312 2.2133173,16.7108261 Z"
          id="t"
        />
      </g>
    </g>
  </Icon>
);

export default Times;
