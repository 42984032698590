// @ts-nocheck
/* eslint-disable max-len */
import React, { type SVGProps } from 'react';

const Icon: React.FunctionComponent<SVGProps<SVGSVGElement>> = ({
  children,
  width,
  height,
  viewBox,
  ...props
}) => (
  <svg width={width} height={height} viewBox={viewBox} {...props}>
    {children}
  </svg>
);

export default Icon;
