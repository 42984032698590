import React, { useCallback, useEffect, useState } from 'react';
import defaultContext from './defaultContext';
import { getUserFromStorage, getUserInfo, invalidateUserCache } from './service/user';
import {
  clearFFFStorage as clearStorage,
  getFromStorage,
  SESSION_STORAGE_KEY,
  SESSION_STORAGE_EVENT,
} from '../utils/storage';
import { isEqualObject } from '../utils/compare';
import { IUserInfo, IUserProvider } from '../../interfaces';

const UserContext = React.createContext(defaultContext);

// Make sure to use UserProvider one level up from where you want to consume values

// Business logic for UserProvider
// TO USE: <UserProvider><App /></UserProvider>

/* Every time the user opens a new tab, we have to validate if the user
currently has a session initiated. This is done by calling the user-data endpoint.
If it's valid, it adds the user information to the sessionStorage. */

const UserProvider = (userProviderProps?: IUserProvider) => {
  const { children } = userProviderProps || { children: null };
  const [userInfo, setUserInfo] = useState<IUserInfo>(getUserFromStorage());
  const [contextReady, setContextReady] = useState(false);
  const handleSetUserInfo = useCallback((newUserInfo: IUserInfo) => {
    if (!isEqualObject(newUserInfo, userInfo)) {
      setUserInfo(newUserInfo);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // set user info from session storage when "sessionStorage" event is triggered
  useEffect(() => {
    const handleStorage = () => {
      const sessionStorageData = getFromStorage({ key: SESSION_STORAGE_KEY.userInfo });
      if (sessionStorageData) handleSetUserInfo(sessionStorageData);
    };

    window.addEventListener(SESSION_STORAGE_EVENT, handleStorage);
    return () => window.removeEventListener(SESSION_STORAGE_EVENT, handleStorage);
  }, []);

  useEffect(() => {
    getUserInfo()
      .then((newUserinfo) => {
        handleSetUserInfo(newUserinfo);
        setContextReady(true);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        setContextReady(true);
      });
  }, [handleSetUserInfo]);

  const displayChildren = contextReady && children;

  return (
    <UserContext.Provider value={{ userInfo, clearStorage, invalidateUserCache }}>
      {displayChildren && children}
    </UserContext.Provider>
  );
};

// This hook is the equivalent of a context consumer
// TO USE: const { clearStorage, userInfo } = useUserContext();
// MAKE SURE: <UserProvider> is a level up
export function useUserContext() {
  const context = React.useContext(UserContext);
  if (!context) {
    throw new Error('You must use the User Provider in order to consume this context.');
  }
  return context;
}

export default UserProvider;
