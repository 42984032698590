/* eslint-disable */
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { getNodeMessage } from '../../utils/timeline';

const Node = ({ isBoxNode, isMobile, message, date, status, link, trackingUrl, bodyDetail }) => {
  const filledShippedNode = (status === 'active' || status === 'past') && message === 'Shipped';
  const showBodyDetail = status === 'active' && bodyDetail;

  return isBoxNode ? (
    <a
      className={cx({
        'challenge-timeline__node': isMobile,
        'challenge-timeline__node-desktop': !isMobile,
        'challenge-timeline__node-cursor': filledShippedNode,
      })}
      href={filledShippedNode ? trackingUrl : null}
      target="_blank"
    >
      <div className={cx('timeline__node__point', status)} />
      <div
        className={
          isMobile ? 'challenge-timeline__node__label' : 'challenge-timeline__node__label-desktop'
        }
      >
        {message}
        {showBodyDetail && (
          <div
            className="timeline__node__current-step-message"
            dangerouslySetInnerHTML={{ __html: bodyDetail }}
          />
        )}
      </div>
    </a>
  ) : (
    <div className="timeline__node">
      <div className={cx('timeline__node__point', status)} />
      {link ? (
        <div className="timeline__node__label__container">
          <a className="timeline__node__label" href={link}>
            {message}
          </a>
          <span className="timeline__node__label">{getNodeMessage('', date)}</span>
        </div>
      ) : (
        <div className="timeline__node__label">{getNodeMessage(message, date)}</div>
      )}
    </div>
  );
};

Node.defaultProps = {
  data: [],
  isBoxNode: false,
  isMobile: true,
  trackingUrl: '',
  showBodyDetail: '',
};

Node.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    message: PropTypes.string,
    order: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    date: PropTypes.string,
    group: PropTypes.string,
    link: PropTypes.string,
  }),
  isBoxNode: PropTypes.bool,
  isMobile: PropTypes.bool,
  trackingUrl: PropTypes.string,
  showBodyDetail: PropTypes.string,
};

export default Node;
