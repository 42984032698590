/* eslint-disable operator-linebreak */
import React, { useState, useRef, useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-cycle
import MenuItem from '../MenuItem';
import { getLink } from '../../../helpers/navigationMenu';
import './SubMenu.css';
import GlobalNavInternalContext from '../../../context/GlobalNavInternalContext';

const SubMenu = ({ label, domain, route, items, isDesktop, renderFunction }) => {
  const [isOpen, setIsOpen] = useState(false);
  const subMenuRef = useRef();

  const { env } = useContext(GlobalNavInternalContext);
  const baseUrl = domain || env.REACT_APP_BASE_URL;

  const handleToggleOpen = () => {
    setIsOpen(!isOpen);
    subMenuRef.current.style.maxHeight = isOpen ? null : `${subMenuRef.current.scrollHeight + 6}px`;
    if (!isOpen) {
      const navRef = subMenuRef.current.parentElement.parentElement.parentElement;
      setTimeout(
        () =>
          // eslint-disable-next-line implicit-arrow-linebreak
          navRef.scrollTo({
            top: subMenuRef.current.offsetTop - 100,
            behavior: 'smooth',
          }),
        700,
      );
    }
  };
  return (
    <div className={classNames('sub-menu', { 'is-open': isOpen })}>
      <li className="sub-menu__label">
        {renderFunction({
          domain: baseUrl,
          route,
          label,
          items,
          isDesktop,
        })}
        {isDesktop ? (
          <i className="fas fa-angle-down" />
        ) : (
          <button onClick={handleToggleOpen} type="button">
            {isOpen ? <i className="fas fa-angle-down" /> : <i className="fas fa-angle-right" />}
          </button>
        )}
      </li>

      <ul ref={subMenuRef}>
        {items.map((item) => (
          <MenuItem key={item.id} item={item} dataTestId={`submenu-${item.label}`} />
        ))}
      </ul>
    </div>
  );
};

SubMenu.defaultProps = {
  label: '',
  route: '',
  items: [],
  domain: null,
  renderFunction: ({ domain, route, label }) => <a href={getLink({ domain, route })}>{label}</a>,
};

SubMenu.propTypes = {
  label: PropTypes.string,
  domain: PropTypes.string,
  route: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape()),
  renderFunction: PropTypes.func,
};

export default SubMenu;
