const options = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

export function convertToCents(valueInDollars) {
  return Number(valueInDollars) * 100;
}

export function convertToDollars(valueInCents) {
  return Number(valueInCents) / 100;
}

export function formatCurrency(value) {
  return Number(value).toLocaleString('en', options);
}

//expects a number in cents
export function formatCurrencyInCents(cents) {
  const valueInDollars = cents / 100;
  return formatCurrency(valueInDollars);
}

export function calculateCartItemTotal(quantity, salePrice, price) {
  const finalPrice = salePrice || price;
  const cartItemTotalInCents = convertToCents(finalPrice) * quantity;
  return formatCurrencyInCents(cartItemTotalInCents);
}
