import React from 'react';
import styles from './CartFooter.module.css';

const CartFooter = ({ cartText }) => (
  <div className={styles.container}>
    <h3>No checkout Necessary</h3>
    <div
      className={styles.cartText}
      //eslint-disable-next-line
      dangerouslySetInnerHTML={{ __html: cartText }}
    />
  </div>
);

export default CartFooter;
