import { useState, useEffect } from 'react';
import { getBoxState } from '../services/homepage';

const INTRO_BOX_STATE = 'SKIPPED_INTRO';

function useHasUpcomingBox() {
  const [hasIntroProduct, setHasIntroProduct] = useState(false);

  useEffect(() => {
    const getIntroProduct = async () => {
      const { boxState } = await getBoxState();
      if (boxState === INTRO_BOX_STATE) {
        setHasIntroProduct(true);
      }
    };

    getIntroProduct();
  }, [getBoxState]);

  return {
    hasIntroProduct,
  };
}

export default useHasUpcomingBox;
