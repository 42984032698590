import React from 'react';
import PropTypes from 'prop-types';
import { trackEvent } from '@fff-web/fff-analytics';

import { getImageByScreenMode } from '../../utils/images';
import './WebCards.css';
import { HOMEPAGE_EXPERIENCE } from '../../utils/constants';
import { trackInviteStarted } from '../../utils/track';

const STARTER_BOX_CTA_LABEL = 'Send Boxes';

const WebCards = ({ screenMode, data }) => {
  const handleOnClick = (ctaLabel) => {
    if (ctaLabel === STARTER_BOX_CTA_LABEL) {
      trackInviteStarted(STARTER_BOX_CTA_LABEL, HOMEPAGE_EXPERIENCE);
    } else {
      trackEvent({ ctaName: ctaLabel }).finally(() => true);
    }
  };
  return (
    <section className="web-cards">
      <div className="web-cards__container">
        <h3>Explore Your Membership</h3>
        <div className="web-cards__cards">
          {data.map(({ id, title, subText, ctaLink, ctaLabel, ...images }) => (
            <div className="web-cards__cards__card" key={id}>
              <div className="web-card-image">
                <img src={getImageByScreenMode(images, screenMode, 'ImageURL')} alt={title} />
              </div>
              <div className="web-card-text">
                <h4>{title}</h4>
                <p>{subText}</p>
              </div>
              <div className="web-card-cta">
                <a
                  className="fff-btn-black-outline-small"
                  href={ctaLink}
                  onClick={() => handleOnClick(ctaLabel)}
                >
                  {ctaLabel}
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

WebCards.defaultProps = {
  screenMode: 'mobile',
  data: [],
};

WebCards.propTypes = {
  screenMode: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      subText: PropTypes.string,
      desktopImage: PropTypes.string,
      tabletImage: PropTypes.string,
      mobileImage: PropTypes.string,
      ctaLink: PropTypes.string,
      ctaLabel: PropTypes.string,
    }),
  ),
};

export default WebCards;
