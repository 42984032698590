import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { isMobile } from 'react-device-detect';
import Button from '@fff-web/fff-ui-shared/Button';
import Modal from '@fff-web/fff-ui-shared/Modal';

import styles from './NewItemAddedModal.module.css';
import { VARIANT_TYPES } from '../../../services/utils/types';

const NewItemAddedModal = ({ cartData, handleOpenCart }) => {
  const prevCardVariantsRef = useRef(null);
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [displayItem, setDisplayItem] = useState(null);
  const priceToShow =
    displayItem?.variantType === VARIANT_TYPES.prospectSingle
      ? displayItem?.price
      : displayItem?.salePrice;

  const handleClickViewCart = () => {
    isMobile && history.push('/shop');
    setIsOpen(false);
    handleOpenCart();
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handdleDisplayModal = () => {
    const { cartDetails, cartVariants } = cartData;
    if (!cartDetails || !cartVariants || !cartDetails?.variantIds) return;

    const currentCartVariantsIds = cartDetails?.variantIds;
    if (
      prevCardVariantsRef.current !== null &&
      currentCartVariantsIds.length > prevCardVariantsRef.current
    ) {
      setDisplayItem(cartVariants[cartVariants.length - 1]);
      setIsOpen(true);
    }
    prevCardVariantsRef.current = currentCartVariantsIds.length;
  };

  useEffect(() => {
    const timeout = setTimeout(
      () => {
        handdleDisplayModal();
      },
      prevCardVariantsRef.current === null ? 700 : 50
    );

    return () => clearTimeout(timeout);
  }, [cartData]);

  if (!displayItem || !isOpen) return null;

  const isRefillProduct = displayItem.variantType === 'REFILL';

  const content = () => (
    <div className={styles.contentContainer}>
      <div className={styles.headerContainer}>
        Added to Your Cart
        <button type="button" className={styles.closeButton} onClick={handleClose}>
          <i className="fal fa-times" />
        </button>
      </div>
      <div className={styles.itemContainer}>
        <div className={styles.imageAndDescriptionContainer}>
          <img src={displayItem?.imageUrl}></img>
          <div className={styles.itemDescription}>
            <p>{displayItem?.cartDisplayName}</p>
            {isRefillProduct && <p>Ships every 90 days</p>}
          </div>
        </div>
        <p className={styles.price}>${Number(priceToShow).toFixed(2)}</p>
      </div>
      <div className={styles.buttonsContainer}>
        <Button variation="primary-outline" onClick={handleClickViewCart}>
          View Cart
        </Button>
        <Button variation="primary">
          <a href="/checkout">Checkout</a>
        </Button>
      </div>
      <p className={styles.disclaimer}>
        Enjoy <strong>free shipping</strong> on orders $49 or more in the contiguous US
      </p>
    </div>
  );

  if (isMobile)
    return (
      <Modal isOpen className={styles.modalPortal} onRequestClose={handleClose}>
        {content()}
      </Modal>
    );

  return (
    <div className={styles.modal}>
      <div className={styles.overlay} onClick={handleClose} />
      {content()}
    </div>
  );
};

export default NewItemAddedModal;
