import queryString from 'query-string';
import {
  getCartVariants,
  getUpdatedCartVariants,
  getCount,
  flattenProducts,
  getVariantQuantityLimits,
} from '../utils/formatResponse';
import request from './request';
import { getCampaignIdQueryParam } from '../utils/page';

const campaignIdQueryParam = getCampaignIdQueryParam();

export async function fetchCart(campaignType, previewCampaign, env, isPublicShopping) {
  const queryParams = isPublicShopping
    ? { campaignType }
    : {
        type: campaignType,
        ...(campaignIdQueryParam && previewCampaign && { campaignId: campaignIdQueryParam }),
      };
  const endpoint = `${
    isPublicShopping ? 'public/api/' : 'api/'
  }1/carts/campaigns?${queryString.stringify(queryParams)}`;
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await request(endpoint, 'GET', env);
    const {
      products: productsWithVariants,
      cartDetails,
      cartId,
      customizeCampaignDetails,
    } = response;
    const { products, variants } = flattenProducts(productsWithVariants);
    const cartVariants = getCartVariants(cartDetails, variants, products);
    const count = getCount(cartVariants);
    return { cartDetails, cartVariants, count, cartId, customizeCampaignDetails };
  } catch (err) {
    throw err;
  }
}

const handlePublicApiResponse = (response, campaignType, currentVariants) => {
  const { cartDetails, products: productsWithVariants } = response.value;
  let cartVariants;
  if (campaignType === 'SHOP') {
    // API response contains all the products in the cart for Shop
    const { products, variants } = flattenProducts(productsWithVariants);
    cartVariants = getCartVariants(cartDetails, variants, products);
  } else {
    cartVariants = getUpdatedCartVariants(currentVariants, cartDetails.variantQuantities);
  }
  const count = getCount(cartVariants);
  return {
    success: true,
    cartDetails,
    cartVariants,
    count,
    productsWithVariants,
  };
};

export async function updateCart(
  variantId,
  operation,
  campaignType,
  currentVariants,
  env,
  cadenceType,
  isPublicShopping,
  itemType,
) {
  const queryParams = {
    operation,
    campaignType,
    ...(campaignIdQueryParam && { campaignId: campaignIdQueryParam }),
    ...(isPublicShopping && itemType && { itemType }),
    ...(cadenceType && { cadenceType }),
  };
  const endpoint = `${
    isPublicShopping ? 'public/api/' : 'api/'
  }1/carts/variants/${variantId}?${queryString.stringify(queryParams)}`;
  // TODO, delete try useless on this file
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await request(endpoint, 'POST', env);
    if (response.success) {
      const { cartDetails, products: productsWithVariants } = response.value;
      let cartVariants;
      if (campaignType === 'SHOP') {
        // API response contains all the products in the cart for Shop
        const { products, variants } = flattenProducts(productsWithVariants);
        cartVariants = getCartVariants(cartDetails, variants, products);
      } else {
        cartVariants = getUpdatedCartVariants(currentVariants, cartDetails.variantQuantities);
      }
      const count = getCount(cartVariants);
      return {
        success: true,
        cartDetails,
        cartVariants,
        count,
        productsWithVariants,
      };
    }
    return response;
  } catch (err) {
    throw err;
  }
}

export const getPurchasedVariants = async (campaignId, campaignType, cartVariants, env) => {
  const endpoint = `api/1/carts/campaigns/${campaignId}/purchases?type=${campaignType}`;
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await request(endpoint, 'GET', env);
    return getVariantQuantityLimits(cartVariants, response.variantQuantities);
  } catch (err) {
    throw err;
  }
};

export async function updateMembership(
  operation,
  campaignType,
  currentVariants,
  env,
  isPublicShopping,
) {
  if (isPublicShopping && operation === 'REMOVE') {
    const introVariants = currentVariants.filter((item) => item.variantType === 'INTRO');
    if (introVariants.length) {
      const endpoint =
        'public/api/1/carts/item-type/from/INTRO/to/PROSPECT_SINGLE?campaignType=SHOP';
      // eslint-disable-next-line no-useless-catch
      try {
        const response = await request(endpoint, 'POST', env);
        if (response.success) {
          return handlePublicApiResponse(response, campaignType, currentVariants);
        }
        return response;
      } catch (err) {
        throw err;
      }
    }
  }

  if (isPublicShopping && operation === 'ADD') {
    const endpoint = 'public/api/1/carts/item-type/from/PROSPECT_SINGLE/to/INTRO?campaignType=SHOP';
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await request(endpoint, 'POST', env);
      if (response.success) {
        return handlePublicApiResponse(response, campaignType, currentVariants);
      }
      return response;
    } catch (err) {
      throw err;
    }
  }

  return null;
}
