export const nmfNavigation = [
  {
    title: 'Shipping',
    key: 'shipping',
  },
  {
    title: 'Survey',
    key: 'survey',
  },
  {
    title: 'Customize',
    key: 'customize',
  },
  {
    title: 'Boost Your Box',
    key: 'boost',
  },
  {
    title: 'Done',
    key: 'done',
  },
];
