import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { initialize } from '@fff-web/fff-analytics';

import SupplierDiversity from './pages/SupplierDiversity';
import Homepage from './pages/Homepage';
import NotFoundError from './pages/NotFoundError';
import './App.css';

function App() {
  // initializate analytics
  initialize();
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/suppliers" component={SupplierDiversity} />
          <Route exact path="/homepage" component={Homepage} />
          <Route exact path="/" component={Homepage} />
          <Route exact path="/ff" component={NotFoundError} />
          <Route component={NotFoundError} />
        </Switch>
      </Router>
    </div>
  );
}

export default withLDProvider({
  clientSideID: process.env.REACT_APP_LD_CLIENT_ID,
  options: { bootstrap: 'localStorage' },
  context: {
    kind: 'user',
    key: 'anonymous',
  },
})(App);
