import React from 'react';
import styles from './ProgressNav.module.css';

const ProgressNavNode = (props) => {
  const { number, item, selected } = props;
  return (
    <div
      className={`${styles.node} ${selected ? styles.selected : ''}`}
      data-testid={`${item.key}-${selected ? 'selected' : ''}`}
    >
      <div className={styles.circle}>{number}</div>
      <div className={styles.title}>{item.title}</div>
    </div>
  );
};

export default ProgressNavNode;
