import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../../components/Layout';
import './NotFoundError.css';

const NotFoundError = ({ baseUrl }) => (
  <Layout pageTitle="Page Not Found - FabFitFun">
    <div className="page-content">
      <article className="not-found-error">
        <h1>404</h1>
        <h2>We’re sorry! The page you are looking for cannot be found!</h2>
        <a href={baseUrl} className="btn-back-home">
          Go back home
        </a>
      </article>
    </div>
  </Layout>
);

NotFoundError.defaultProps = {
  baseUrl: process.env.REACT_APP_SITE_URL,
};

NotFoundError.propTypes = {
  baseUrl: PropTypes.string,
};

export default NotFoundError;
