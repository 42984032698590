import React from 'react';
import get from 'lodash/get';
import { HOSTNAMES } from '../services/utils/env';
import { PAGE, getPage } from '../services/utils/page';

const InternalContext = React.createContext({
  env: null,
  hostname: null,
  page: null,
  isShopPage: null,
  isEditPage: null,
  isAddOnsPage: null,
  isBoostsPage: null,
  isNewMemberBoostsPage: null,
  isNewMemberCustomize: null,
});

const InternalContextProvider = ({ env, children }) => (
  <InternalContext.Provider
    // TODO: Add useMemo here
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    value={{
      env,
      hostname: get(HOSTNAMES, env),
      page: getPage(),
      isShopPage: getPage() === PAGE.shop,
      isEditPage: getPage() === PAGE.edit,
      isAddOnsPage: getPage() === PAGE['add-ons'],
      isBoostsPage: getPage() === PAGE.boosts,
      isNewMemberBoostsPage: getPage() === PAGE['new-member-boosts'],
      isNewMemberCustomize: getPage() === PAGE['new-member-customize'],
    }}
  >
    {children}
  </InternalContext.Provider>
);

export const useInternalContext = () => {
  const context = React.useContext(InternalContext);
  return context;
};

export default InternalContextProvider;
