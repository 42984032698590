import { useEffect, useReducer } from 'react';

import { actions, homepageReducer, initialState } from '../Store';
import { getInvitePopupExpirationFromStorage } from '../helpers';

const { getShowInvitePopupSuccess, getShowInvitePopupFail } = actions;

const useShowInvitePopup = (key) => {
  const [{ showInvitePopup }, stateDispatch] = useReducer(homepageReducer, initialState());

  useEffect(() => {
    if (Date.now() > getInvitePopupExpirationFromStorage(key)) {
      stateDispatch(getShowInvitePopupSuccess());
    } else {
      stateDispatch(getShowInvitePopupFail());
    }
  }, []);
  return { showInvitePopup, stateDispatch };
};

export default useShowInvitePopup;
