import React, { useContext, useEffect, useRef, useCallback, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import GlobalCart from '@fff-web/fff-cart';

import { filterCommunityItems } from '../../utils/filters';

import MenuItem from './MenuItem';
import UserProfile from '../UserProfile';
import GlobalNavInternalContext from '../../context/GlobalNavInternalContext';
import './NavigationMenu.css';

const logged = 'loginState';
const logout = 'logoutState';

const NavigationMenu = ({
  routes,
  isLoggedIn,
  isVisible,
  isDesktop,
  userInfo,
  stickyCart,
  newMemberState,
  onNewMemberStateFetched,
}) => {
  const { mainProps, env, membershipInfo } = useContext(GlobalNavInternalContext);
  const navRef = useRef();

  const setNavHeight = useCallback(() => {
    if (navRef.current && !isDesktop) {
      navRef.current.style.height = `${window.innerHeight - navRef.current.offsetTop}px`;
    }
    if (navRef.current && isDesktop) {
      navRef.current.style.height = null;
    }
  }, [isDesktop]);

  useEffect(() => {
    window.addEventListener('resize', setNavHeight);

    return () => window.removeEventListener('resize', setNavHeight);
  }, [setNavHeight]);

  useEffect(() => {
    setNavHeight();
  }, [isVisible, isDesktop, setNavHeight]);

  return (
    <nav
      ref={navRef}
      className={classNames('navigation-bar', {
        'is-visible': isVisible,
        'is-logout__nav': !isLoggedIn,
      })}
    >
      {!isDesktop && isLoggedIn && (
        <ul>
          <UserProfile isDesktop={isDesktop} routes={routes.userProfile} userInfo={userInfo} />
        </ul>
      )}
      <ul>
        {routes[isLoggedIn ? logged : logout]
          .filter((item) =>
            filterCommunityItems(item, membershipInfo?.membership_state, membershipInfo?.flags),
          )
          .map((item) => (
            <MenuItem key={item.id} item={item} isDesktop={isDesktop} />
          ))}
      </ul>
      {isDesktop && isLoggedIn && (
        <div className="user-container">
          <UserProfile
            isDesktop={isDesktop}
            routes={routes.userProfile}
            userInfo={userInfo}
            membershipInfo={membershipInfo}
          />
          <div
            className={classNames('shopping-cart', {
              'sticky-cart': stickyCart,
            })}
          >
            {!mainProps.hideCart && (
              <GlobalCart
                {...mainProps}
                env={env.name}
                newMemberState={newMemberState}
                onNewMemberStateFetched={onNewMemberStateFetched}
              />
            )}
          </div>
        </div>
      )}
    </nav>
  );
};

NavigationMenu.defaultProps = {
  isLoggedIn: false,
  isVisible: false,
  isDesktop: false,
  routes: { loginState: [], logoutState: [], userProfile: [] },
  userInfo: {},
  stickyCart: false,
};

NavigationMenu.propTypes = {
  isLoggedIn: PropTypes.bool,
  isVisible: PropTypes.bool,
  isDesktop: PropTypes.bool,
  routes: PropTypes.shape({
    loginState: PropTypes.arrayOf(PropTypes.shape()),
    logoutState: PropTypes.arrayOf(PropTypes.shape()),
    userProfile: PropTypes.arrayOf(PropTypes.shape()),
  }),
  userInfo: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    role: PropTypes.string,
    subscriptionState: PropTypes.string,
    subscriptionType: PropTypes.string,
    avatar: PropTypes.string,
  }),
  stickyCart: PropTypes.bool,
};

export default NavigationMenu;
