import React from 'react';

import styles from './Tab.module.css';
import classNames from 'classnames';

const Tab = ({ name, active, quantity, onClick, onlyTab }) => {
  const buttonText = quantity > 0 ? `${name} (${quantity})` : name;

  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames(styles.button, {
        [styles.onlyTab]: onlyTab,
        [styles.active]: active && !onlyTab,
      })}
    >
      {buttonText}
    </button>
  );
};

Tab.defaultProps = {
  active: false,
};

export default Tab;
