import React from 'react';
import PropTypes from 'prop-types';
import { trackElement } from '@fff-web/fff-analytics';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import { links, notSubscribedLinks } from './boxButtonLinks';
import { handleTrackAndRedirect } from '../../utils/track';

const NOT_SUBSCRIBED = 'NOT_SUBSCRIBED';
const INVOICE_HISTORY_LABEL = 'INVOICE HISTORY';
const ORDERS_BUTTON = {
  label: 'ORDERS',
  href: `${process.env.REACT_APP_SITE_URL}/my-account/orders`,
};

const replaceInvoiceHistory = (items) =>
  items.map((item) => {
    if (item.label === INVOICE_HISTORY_LABEL) return ORDERS_BUTTON;
    return item;
  });

const QuickLinks = ({ statusId, flags }) => {
  const isSubscribed = statusId !== NOT_SUBSCRIBED;
  const boxLinks = isSubscribed ? links : notSubscribedLinks;
  const displayedLinks = flags && flags.ordersButton ? replaceInvoiceHistory(boxLinks) : boxLinks;

  return (
    <div className="buttons-container">
      {displayedLinks.map(({ href, label }) => (
        <button
          type="button"
          key={href}
          onClick={() =>
            handleTrackAndRedirect(
              () => trackElement({ elementName: `${label} - BOX STATUS` }),
              href,
            )
          }
          className="mybox-button"
        >
          {label}
        </button>
      ))}
    </div>
  );
};

QuickLinks.defaultProps = {
  statusId: '',
};

QuickLinks.propTypes = {
  statusId: PropTypes.string,
};

export default withLDConsumer()(QuickLinks);
