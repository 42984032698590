import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Node from './Node';
import { parseNodeData } from '../../utils/timeline';
import { isLastElementAttrEqual } from '../../utils/array';

const NodeBox = ({ label, nodes }) => (
  <div className={cx('timeline__node-box', { colored: label })}>
    {label && <div className="timeline__node-box__label">{label}</div>}
    <div className="timeline__node-box__container">
      {nodes &&
        nodes.map((node, index) => {
          const { message, status, link, date } = parseNodeData(node);
          return (
            <Fragment key={node.id}>
              <Node message={message} status={status} link={link} date={date} />
              {index < nodes.length - 1 && (
                <div
                  className={cx('timeline__node-box__line', { solid: node.status === 'past' })}
                />
              )}
            </Fragment>
          );
        })}
      <div
        className={cx('timeline__line', { solid: isLastElementAttrEqual(nodes, 'status', 'past') })}
      />
    </div>
  </div>
);

Node.defaultProps = {
  data: [],
};

Node.propTypes = {
  label: PropTypes.string,
  nodes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      message: PropTypes.string,
      order: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      date: PropTypes.string,
      group: PropTypes.string,
    }),
  ),
};
export default NodeBox;
