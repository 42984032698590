import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './Avatar.css';

const Avatar = ({ image }) => {
  const [isImageFailing, setIsImageFailing] = useState(false);

  return (
    <div className="avatar">
      {image && !isImageFailing ? (
        <img src={image} alt="Avatar" onError={() => setIsImageFailing(true)} />
      ) : (
        <i className="fas fa-user-circle menu" />
      )}
    </div>
  );
};

Avatar.defaultProps = {
  image: null,
};

Avatar.propTypes = {
  image: PropTypes.string,
};

export default Avatar;
