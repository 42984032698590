const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const getNodeMessage = (message, date) => {
  if (date) {
    try {
      const jsDate = new Date(`${date.replace(/-/g, '/')} UTC`);

      return `${message ? `${message} ` : ''}${monthNames[jsDate.getMonth()]}. ${jsDate.getDate()}`;
    } catch (error) {
      return message;
    }
  }

  return message;
};

const NODES = {
  'Add-Ons': `${process.env.REACT_APP_SITE_URL}/add-ons`,
  Customization: `${process.env.REACT_APP_SITE_URL}/customize`,
};

export const parseNodeData = (node) => {
  const { message } = node;
  const link = NODES[message];
  return link ? { ...node, link } : node;
};
