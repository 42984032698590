import React from 'react';
import UserWayIcon from '../images/userway.svg';
import styles from './UserWay.module.css';

const UserWay = () => (
  <div className={styles.userWayContainer}>
    <img
      className={styles.userWayImage}
      id="accessibilityWidget"
      alt="accessibility"
      src={UserWayIcon}
    />
  </div>
);

export default UserWay;
