import React from 'react';
import useFeatureFlagExperiment from '../../hooks/useFeatureFlagExperiment';

/**
 * This HOC render the wrapped component or return an empty component
 * depending on the experiment variations that comes from optimizely client
 */

const withABTest =
  (featureFlag, WrappedComponent, possibleVariations = []) =>
  (props) => {
    const [ruleKey, variation] = useFeatureFlagExperiment(featureFlag);
    return (
      <WrappedComponent
        {...props}
        experimentName={ruleKey}
        variationName={variation}
        isVariations={possibleVariations.includes(variation)}
      />
    );
  };

export default withABTest;
