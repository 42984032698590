import { useState, useEffect } from 'react';
import { FFFStorage, Compare } from '@fff-web/fff-utilities';
import { getBoxState } from '../services';

const boxStateKey = 'box-state';
const cacheDurationInMs = 15 * 60 * 1000; //15 minutes
const HIDE_SWAP_FOR = ['SKIPPED_INTRO']; // In case more than one state makes the Swap for Credit link to go away

const useBoxState = (isLogin) => {
  const [boxState, setBoxState] = useState(FFFStorage.getFromStorage({ key: boxStateKey }));

  useEffect(() => {
    if (isLogin) {
      const boxStateCache = FFFStorage.getFromStorage({ key: boxStateKey });
      if (boxStateCache) {
        if (!Compare.isEqualObject(boxState, boxStateCache)) {
          setBoxState(boxStateCache);
        }
      } else {
        getBoxState().then((content) => {
          setBoxState(content);
          FFFStorage.setToStorage({
            key: boxStateKey,
            value: content,
            durationInMs: cacheDurationInMs,
          });
        });
      }
    }
  }, [boxState, isLogin]);
  const hideSwap = HIDE_SWAP_FOR.includes(boxState);
  return { boxState, hideSwap };
};

export default useBoxState;
