import { useContext, useEffect, useState } from 'react';
import { getTimer } from '../services';
import { newMemberStateUrlMap, findNewMemberStateIndex } from '../helpers/newMemberStateUrlMap';
import { CUSTOMIZING, USER_ONBOARDING_CUSTOMIZING } from '../helpers/constants/newMemberStates';
import GlobalNavInternalContext from '../context/GlobalNavInternalContext';

// THIS HOOK IS USED TO REDIRECT NEW MEMBERS BACK INTO THE NMF IF THEY STILL HAVE AN ACTIVE TIMER OBJ
export function useGlobalNavTimerRedirect(env, fffSocialConfig = {}) {
  const { onNewMemberStateFetched } = useContext(GlobalNavInternalContext);
  const [response, setResponse] = useState(undefined);

  useEffect(() => {
    getTimer(env)
      .then(setResponse)
      .catch(() => {
        //If there's an error with the endpoint, don't worry about redirecting the user
      });
  }, []);

  useEffect(() => {
    if (response === undefined) {
      return;
    }

    if (response === null) {
      onNewMemberStateFetched(null);
      if (
        fffSocialConfig &&
        fffSocialConfig.page &&
        fffSocialConfig.urlsToRedirect &&
        fffSocialConfig.urlsToRedirect.length > 0 &&
        fffSocialConfig.urlsToRedirect.includes(window.location.pathname)
      ) {
        window.location.href = env.REACT_APP_BASE_URL + fffSocialConfig.page;
      }
      return;
    }
    const {
      newMemberState,
      lastVisitedCustomizeQuestionId,
      incompleteSubscriptionUuid,
      timerType,
    } = response;
    //hard coding here so that once we are using the redis timer this block of code can easily be removed
    if (!!incompleteSubscriptionUuid) {
      window.location.href = `${env.REACT_APP_BASE_URL}/gtb-thank-you/shipping`;
    } else {
      const stateIndex = findNewMemberStateIndex(newMemberState);
      if (stateIndex === -1) {
        onNewMemberStateFetched(newMemberState);
        return;
      }
      const statePath =
        timerType === 'REACTIVATION'
          ? newMemberStateUrlMap[stateIndex].rmUrls[0]
          : newMemberStateUrlMap[stateIndex].nmUrls[0];

      const isCustomizePage = [CUSTOMIZING, USER_ONBOARDING_CUSTOMIZING].includes(newMemberState);

      window.location.href =
        env.REACT_APP_BASE_URL +
        statePath +
        (isCustomizePage && !!lastVisitedCustomizeQuestionId
          ? `?q=${lastVisitedCustomizeQuestionId}`
          : '');
    }
  }, [fffSocialConfig, response]);
}

export default useGlobalNavTimerRedirect;
