import React from 'react';
import './LegalFooter.css';
import cx from 'classnames';
import { trackElement } from '@fff-web/fff-analytics';

import useFooterContent from '../hooks/useFooterContent';

const LEGAL_ID = 'l-0';
export const LegalFooter = (props) => {
  const { defaultYear } = useFooterContent();
  const { legals, buildPath, shippingState, isLogin, isTablet } = props;
  const footerLinkStyles = cx({
    pointer: legals.find((x) => x.id !== LEGAL_ID),
  });
  const footerStyles = cx({
    'tablet-legal-second-line': isTablet,
  });
  const tabletStyles = cx({
    'tablet-legal-footer': isTablet,
  });
  const clickListener = (buildedPath, trakingName) => {
    if (trakingName) {
      trackElement({
        elementName: trakingName,
        callback: () => window.location.replace(buildedPath),
      });
    }
  };
  const aTag = (legal, index) =>
    legal.route ? (
      <a
        className={`legal-footer-items order-${index} ${footerLinkStyles}`}
        alt={legal.label ? legal.label.replace('defaultYear', defaultYear) : ''}
        key={legal.id}
        href={
          legal.trakingName ? undefined : buildPath(legal.route, legal.subdomain, legal.prodOnly)
        }
        onClick={() =>
          clickListener(buildPath(legal.route, legal.subdomain, legal.prodOnly), legal.trakingName)
        }
      >
        {legal.label ? legal.label.replace('defaultYear', defaultYear) : ''}
        <span className="fff-shared-footer-legal-separator">{legal.separator}</span>
      </a>
    ) : (
      <div key={legal.id} className={`legal-footer-items order-${index} legal-footer-text-only`}>
        {legal.label ? legal.label.replace('defaultYear', defaultYear) : ''}
      </div>
    );

  return (
    <div className="bottom-legal-footer-container">
      {isTablet && (
        <div id="first" className={`fff-shared-footer-legal ${tabletStyles}`}>
          {legals.filter((link) => link.group === 1).map((legal, index) => aTag(legal, index))}
        </div>
      )}
      <div id="second" className={`fff-shared-footer-legal ${footerStyles}`}>
        {legals
          .filter((link) => (isTablet && link.group === 2) || !isTablet)
          .map((legal, index) => aTag(legal, index))}
        {(shippingState === 'CA' || !isLogin) && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            id="ot-sdk-btn"
            className={`ot-sdk-show-settings legal-footer-items order-last ${footerLinkStyles}`}
          >
            Do Not Sell or Share my Personal Information
          </a>
        )}
      </div>
    </div>
  );
};
