import { ReferrerQueryString } from '@fff-web/fff-analytics';
import { toLowerCase } from './string';
import { SUBSCRIPTION_STATE, REACTIVATION_URL, SUBSCRIPTION_TYPE } from '../utils/constants';
import { updateRouteForTrial } from '../utils/dynamicLinks';

export const getLink = (item) => `${item.domain || ''}${item.route}`;

const addOnsId = 'add-ons';
const editId = 'edit';
const shopId = 'shop';
const communityId = 'community';
const idMap = {
  SEASON: addOnsId,
  EDIT: editId,
};

const needSwapForStatus = (livesMap) =>
  !livesMap[editId].live &&
  !livesMap[addOnsId].live &&
  livesMap[editId].status !== livesMap[addOnsId].status &&
  (livesMap[editId].status === 'MID' ||
    (livesMap[editId].status === 'BEFORE_START' && livesMap[addOnsId].status !== 'MID'));

export const needSwap = (livesMap) =>
  (livesMap[editId].live && !livesMap[addOnsId].live) || needSwapForStatus(livesMap);

export const rearrangeRoutes = (
  routes = {},
  lives = [],
  subscriptionState = null,
  subscriptionType = null,
) => {
  try {
    const newRoutes = JSON.parse(JSON.stringify(routes));
    const defaultMap = { [addOnsId]: {}, [editId]: {} };
    const livesMap = lives
      ? lives.reduce((prev, { id, ...live }) => ({ ...prev, [idMap[id] || id]: live }), defaultMap)
      : defaultMap;

    if (subscriptionState === SUBSCRIPTION_STATE.expired) {
      const communityRouteId = routes.loginState?.findIndex(({ id }) => id === communityId);
      const communityRoute = routes.loginState?.[communityRouteId];
      const communitySubRoutes = communityRoute?.subRoutes;
      if (
        newRoutes.loginState &&
        typeof communityRouteId === 'number' &&
        communityRouteId > -1 &&
        communityRoute
      ) {
        newRoutes.loginState[communityRouteId] = {
          ...communityRoute,
          domain: REACTIVATION_URL,
          subRoutes: communitySubRoutes?.map((subRoute) => ({
            ...subRoute,
            domain: REACTIVATION_URL,
            route: '',
          })),
        };
      }
    }

    if (needSwap(livesMap) && routes.loginState?.length > 0) {
      // Swap Routes
      const shopRouteId = routes.loginState?.findIndex(({ id }) => id === shopId);
      const editRouteId = routes.loginState?.[shopRouteId]?.subRoutes?.findIndex(
        ({ id }) => id === editId,
      );
      const addOnsRouteId = routes.loginState?.findIndex(({ id }) => id === addOnsId);
      const editRoute = routes.loginState?.[shopRouteId]?.subRoutes?.[editRouteId];
      const addOnsRoute = routes.loginState?.[addOnsRouteId];
      if (newRoutes.loginState?.[shopRouteId]?.subRoutes?.[editRouteId] && addOnsRoute) {
        newRoutes.loginState[shopRouteId].subRoutes[editRouteId] = {
          ...addOnsRoute,
          label: toLowerCase(addOnsRoute.label),
          subRoutes: undefined,
        };
      }
      if (newRoutes.loginState?.[addOnsRouteId] && editRoute) {
        newRoutes.loginState[addOnsRouteId] = { ...editRoute, subRoutes: addOnsRoute?.subRoutes };
      }
    }

    if (
      subscriptionType === SUBSCRIPTION_TYPE.trial &&
      subscriptionState === SUBSCRIPTION_STATE.active
    ) {
      newRoutes.loginState = updateRouteForTrial(newRoutes.loginState);
    }

    return {
      ...routes,
      loginState: newRoutes.loginState?.map((route) => ({
        ...route,
        live: (livesMap[route.id] && livesMap[route.id].live) || false,
      })),
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return routes;
  }
};

export const getStarterBoxURL = (route) => `${route}?referrer=${ReferrerQueryString.globalNav}`;
