import { useState, useEffect } from 'react';

const windowSizes = {
  small: 576,
  tablet: 992,
};

export function getWindowSize() {
  const { innerWidth: width } = window;
  if (width < windowSizes.small) {
    return 'mobile web';
  }
  if (width < windowSizes.tablet) {
    return 'tablet';
  }
  return 'desktop';
}

export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleResize() {
      setWindowSize(getWindowSize());
    }

    global.window?.addEventListener('resize', handleResize);
    return () => global.window?.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
}
