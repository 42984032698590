import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './HamburgerMenuIcon.css';

const HamburgerMenuIcon = ({ isOpen, onClick }) => (
  <button
    type="button"
    data-testid="hamburger-menu-icon"
    className="hamburger-menu-icon"
    onClick={onClick}
  >
    <div className={classNames({ 'is-open': isOpen })}>
      <span />
      <span />
    </div>
  </button>
);

HamburgerMenuIcon.defaultProps = {
  isOpen: false,
  onClick: () => {},
};

HamburgerMenuIcon.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
};

export default HamburgerMenuIcon;
