import React from 'react';

import circleLoading from './fff-circle-loading.png';
import './Loading.css';

export const Loading = () => (
  <div className="loading-icon-container">
    <img className="loading-icon" src={circleLoading} alt="Loading icon" />
  </div>
);
