export const constructUrlWithParams = (url) => {
  const queryParams = window.location.search; // e.g., "?param=value"
  const [baseUrl, fragment] = url.split('#');
  const separator = baseUrl.includes('?') ? '&' : '?';
  const newUrl =
    baseUrl +
    (queryParams ? separator + queryParams.slice(1) : '') +
    (fragment ? `#${fragment}` : '');

  return newUrl;
};
