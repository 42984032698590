import React from 'react';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import styles from './Tooltip.module.css';

const Tooltip = ({ place, id, className, delayHide = 0 }) => (
  <ReactTooltip
    className={classNames(styles.tooltip, className)}
    place={place}
    type="dark"
    effect="solid"
    id={id}
    delayHide={delayHide}
  />
);

export default Tooltip;
